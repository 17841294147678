import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

const burgerMenuSlice = createSlice({
  name: "burgerMenu",
  initialState,
  reducers: {
    openMenu: (state, action) => {
      state.isOpen = true;
    },
    closeMenu: (state, action) => {
      state.isOpen = false;
    },
  },
});

export const { openMenu, closeMenu } = burgerMenuSlice.actions;
export default burgerMenuSlice.reducer;
