import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMoreWatchedNews } from "../../services/newsApi";

const initialState = {
  results: {
    news: [],
  },
  error: null,
  status: "idle",
};

export const fetchMoreWatchedNews = createAsyncThunk(
  "moreWatchedNews/fetchMoreWatchedNews",
  async () => {
    return getMoreWatchedNews();
  }
);

const moreWatchedNewsSlice = createSlice({
  name: "moreWatchedNews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMoreWatchedNews.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchMoreWatchedNews.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results.news = payload;
    });
    builder.addCase(fetchMoreWatchedNews.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default moreWatchedNewsSlice.reducer;
