import styled from "styled-components";

const StyledPageControl = styled.div`
  /* display: grid;
  grid-template-columns: 60px 1fr 60px; */
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
`;
const StyledNavigationButton = styled.button`
  display: flex;
  align-items: center;
  gap: 11px;
  background-color: white;
  color: green;
  transition: color 0.5s;
  svg {
    width: 50px;
    height: 20px;
  }
  path {
    transition: fill 0.5s;
    fill: green;
  }
  &:hover {
    color: red;
  }
  &:hover path {
    fill: red;
  }
`;
const StyledNumberButtonList = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const StyledNumberButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 30px;
  font-size: 20px;
  font-weight: 700;
  background-color: ${({ isActive }) => (isActive ? "green" : "white")};
  color: ${({ isActive }) => (isActive ? "white" : "green")};
  transition: color 0.3s;
  &:hover {
    color: white;
    background-color: green;
  }
`;

export {
  StyledPageControl,
  StyledNavigationButton,
  StyledNumberButton,
  StyledNumberButtonList,
};
