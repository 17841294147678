import styled from "styled-components";
import { media } from "../../ui/media";

const StyledVideoGallery = styled.div`
  padding: 29px 100px 48px;
  width: 100%;
  height: 412px;
  display: flex;
  justify-content: center;
  gap: 20px;
  video {
    border-radius: 37px;
    max-width: 610px;
  }
  ${media.TABLET} {
    height: max-content;
    padding: 20;
    flex-direction: row;
    align-items: center;
    video {
      border-radius: 15px;
      max-width: 340px;
    }
  }
  ${media.PHONE} {
    flex-direction: column;

    video {
      border-radius: 15px;
      max-width: 340px;
    }
  }
`;

export { StyledVideoGallery };
