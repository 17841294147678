import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNewsByIdentificator } from "../../services/newsApi";

const initialState = {
  results: {
    news: [],
  },
  error: null,
  status: "idle",
};

export const fetchNewsById = createAsyncThunk(
  "newsById/fetchNewsById",
  async (id) => {
    return getNewsByIdentificator(id);
  }
);

const newsByIdSlice = createSlice({
  name: "newsById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNewsById.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchNewsById.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results = payload;
    });
    builder.addCase(fetchNewsById.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default newsByIdSlice.reducer;
