import React from "react";
import { StyledPhotogallery } from "./styles";
import multimedia1 from "../../assets/multimedia1.webp";
import multimedia2 from "../../assets/multimedia2.webp";
import multimedia3 from "../../assets/multimedia3.webp";

const Photogallery = () => {
  return (
    <StyledPhotogallery>
      <img src={multimedia1} alt="multimedia1" />
      <img src={multimedia2} alt="multimedia2" />
      <img src={multimedia3} alt="multimedia3" />
    </StyledPhotogallery>
  );
};

export default Photogallery;
