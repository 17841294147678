import React from "react";
import {
  StyledGalkanPay,
  StyledGalkanPayContainer,
  StyledGalkanPayTitle,
  StyledGalkanPayApplication,
  StyledGalkanPayDescription,
  StyledGalkanPayImage,
} from "./styles";

import playmarket from "../../assets/playmarket.webp";
import appstore from "../../assets/appstore.webp";
import { useAppSelector } from "../../store/hooks/hooks";
import { getLanguage } from "../../store/selectors/languageSelectors";

const GalkanPay = () => {
  const language = useAppSelector(getLanguage);

  return (
    <StyledGalkanPay>
      <StyledGalkanPayContainer>
        <StyledGalkanPayTitle>GALKAN TÖLEG</StyledGalkanPayTitle>
        <StyledGalkanPayApplication>
          <img src={playmarket} alt="playmarket" />
          <img src={appstore} alt="appstore" />
        </StyledGalkanPayApplication>
        <StyledGalkanPayDescription>
          {language === "en"
            ? `You can pay payments to the Ministry of Internal Affairs of Turkmenistan through our application «Galkan töleg»!`
            : language === "ru"
            ? `Оплатить выплаты МВД Туркменистана вы можете через наше приложение «Galkan töleg»!`
            : `Türkmenistanyň Içeri işler ministirliginiň töleglerini «Galkan töleg»
              programmamyz arkaly töläp bilersiňiz!`}
        </StyledGalkanPayDescription>
      </StyledGalkanPayContainer>
      <StyledGalkanPayImage>
        <div></div>
      </StyledGalkanPayImage>
    </StyledGalkanPay>
  );
};

export default GalkanPay;
