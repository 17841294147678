import styled from "styled-components";
import { media } from "../../ui/media";

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2c5d5b;
  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;
const StyledFooterContainer = styled.div`
  width: 100%;
  height: 310px;
  display: grid;
  grid-template-columns: 410px 1fr;
  padding: 0 150px;
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: max-content;
  }
  ${media.PHONE} {
  }
`;

const StyledFooterImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;

const StyledTiimLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 73px;
    height: 83px;
  }
  p {
    width: 245px;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #ffffff;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;
const StyledTiimBuilding = styled.div`
  img {
    width: 100%;
    height: 154px;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;

const StyledFooterNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 42px;

  padding-left: 98px;
  padding-right: 32px;
  color: #ffffff;

  h2 {
    font-size: DM Sans;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }
  a {
    font-size: DM Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: #ffffff;
  }
  ${media.TABLET} {
    padding: 0;
    justify-content: center;
    gap: 50px;
  }
  ${media.PHONE} {
    justify-content: space-between;
    flex-direction: column;

    margin-left: 20px;
    padding: 0;
  }
`;

const StyledNavList = styled.div`
  display: flex;
  flex-direction: column;
  &:nth-child(2) {
    margin: 0 98px;
  }
  a,
  h2,
  p {
    margin-bottom: 25px;
  }
  ${media.TABLET} {
    h2 {
      margin-bottom: 15px;
    }
    a,
    p {
      margin-left: 0px;
    }
    &:nth-child(2) {
      width: max-content;
      margin: 0;
    }
  }
  ${media.PHONE} {
    a,
    p {
      margin-left: 50px;
    }
  }
`;

const StyledFooterBottom = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 0.5px solid #ffffff;
  p {
    font-size: DM Sans;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
`;

export {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterImage,
  StyledTiimLogo,
  StyledFooterNav,
  StyledNavList,
  StyledTiimBuilding,
  StyledFooterBottom,
};
