import styled from "styled-components";
import { media } from "../../ui/media";

const StyledMiaCodex = styled.div`
  padding: 48px 150px 149px;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;
const StyledTitle = styled.h2`
  margin-bottom: 39px;
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  text-align: center;

  ${media.TABLET} {
    margin-bottom: 29px;
    font-size: 26px;
    line-height: 30px;
  }
  ${media.PHONE} {
    font-size: 20px;
    line-height: 20px;
  }
`;
const StyledMiaCodexContainer = styled.div`
  display: flex;
`;
const StyledMiaCodexItemsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  @media only screen and (max-width: 1752px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }
  @media only screen and (max-width: 1550px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  grid-gap: 20px;

  justify-content: space-between;
  margin-left: 23px;
  margin-right: 23px;
  ${media.TABLET} {
    display: flex;
    margin-left: 0;
    margin-right: 0;
    overflow: auto; /* Ekran dışına taşan öğeler için kaydırma oluşturur */
    white-space: nowrap; /* Öğelerin yan yana sığmasını sağlar */
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  ${media.PHONE} {
  }
`;
const StyledMiaCodexItem = styled.div`
  border-radius: 12px;
  box-shadow: 0px 23px 41px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  place-self: center;
  img {
    width: 208px;
    height: 277px;
    border-radius: 12px;
  }
  ${media.TABLET} {
    margin: 15px;
    box-shadow: 0px 0px 13px 0px #00000069;
  }
  ${media.PHONE} {
  }
`;
const StyledPrevious = styled.div`
  margin-top: 91px;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #000000;
  cursor: pointer;
  svg {
    path {
      fill: #000000;
    }
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;
const StyledNext = styled.div`
  margin-top: 91px;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #000000;
  cursor: pointer;
  svg {
    path {
      fill: #000000;
    }
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;

export {
  StyledMiaCodex,
  StyledTitle,
  StyledMiaCodexContainer,
  StyledMiaCodexItemsContainer,
  StyledMiaCodexItem,
  StyledPrevious,
  StyledNext,
};
