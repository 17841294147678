import React, { useEffect } from "react";
import {
  StyledOtherNews,
  StyledTitleContainer,
  StyledTitle,
  StyledTitleLine,
  StyledTitleButton,
  StyledNewsContainer,
  StyledNewsItem,
  StyledNewsImage,
  StyledNewsViewsContainer,
  StyledNewsViews,
  StyledNewsTitle,
} from "./styles";

import IconSelector from "../../ui/IconSelector";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { getLanguage } from "../../store/selectors/languageSelectors";
import { fetchRecentOtherNews } from "../../store/slices/recentOtherNewsSlice";
import { getRecentOtherNews } from "../../store/selectors/recentOtherNewsSelectors";
import { formatDate } from "../formatDate";
const OtherNews = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(getLanguage);
  useEffect(() => {
    dispatch(fetchRecentOtherNews());
  }, [dispatch]);
  const recentOtherNews = useAppSelector(getRecentOtherNews);

  return (
    <StyledOtherNews>
      <StyledTitleContainer>
        <StyledTitle>Beýleki habarlar</StyledTitle>
        <StyledTitleLine>
          <IconSelector id="title-line" />
        </StyledTitleLine>
        <StyledTitleButton>Ählisini görmek</StyledTitleButton>
      </StyledTitleContainer>
      <StyledNewsContainer>
        {recentOtherNews.map((item) => (
          <StyledNewsItem key={item.ID} to={"/habarlar/" + item.ID}>
            <StyledNewsImage>
              <img
                src={`${process.env.REACT_APP_FILE_URL}/${item.Image}`}
                alt="newsimage"
              />
            </StyledNewsImage>
            <StyledNewsViewsContainer>
              <StyledNewsViews>
                <IconSelector id="views" />
                <p>{item.Views}</p>
              </StyledNewsViews>

              <p>{formatDate(item.CreatedAt)}</p>
            </StyledNewsViewsContainer>
            <StyledNewsTitle>
              {language === "en"
                ? item.TitleTm
                : language === "ru"
                ? item.TitleRu
                : item.TitleTm}
            </StyledNewsTitle>
          </StyledNewsItem>
        ))}
      </StyledNewsContainer>
    </StyledOtherNews>
  );
};

export default OtherNews;
