import React from "react";
import MainSlider from "../../components/MainSlider/MainSlider";
import MinistryNews from "../../components/MinistryNews/MinistryNews";
import PaymentTypes from "../../components/PaymentTypes/PaymentTypes";
import OtherNews from "../../components/OtherNews/OtherNews";
import GovernmentWebsites from "../../components/GovernmentWebsites/GovernmentWebsites";
import GalkanPay from "../../components/GalkanPay/GalkanPay";
import Journal from "../../components/Journal/Journal";

const MainPage = () => {
  return (
    <div>
      <MainSlider />
      <MinistryNews />
      <PaymentTypes />
      <OtherNews />
      <Journal />
      <GovernmentWebsites />
      <GalkanPay />
    </div>
  );
};

export default MainPage;
