import React, { useEffect, useState } from "react";
import Carousel from "../../carousel/Carousel";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { fetchAllMainSliders } from "../../store/slices/mainSliderSlice";
import { getAllMainSliders } from "../../store/selectors/mainSliderSelectors";
import { getLanguage } from "../../store/selectors/languageSelectors";
import {
  StyledMainSlider,
  StyledMainSliderItem,
  StyledSliderImage,
  StyledSliderText,
  StyledSliderTextBackground,
  StyledSliderTitle,
  StyledSliderDescription,
  StyledSliderImageBackground,
  StyledCarouselIndicators,
  StyledCarouselIndicatorsItem,
} from "./styles";

const MainSlider = () => {
  const [indicatorIndex, setIndicatorIndex] = useState(0);
  const dispatch = useAppDispatch();
  const mainSliders = useAppSelector(getAllMainSliders);
  const language = useAppSelector(getLanguage);
  useEffect(() => {
    dispatch(fetchAllMainSliders());
  }, [dispatch]);

  const handleIndicators = (e) => {
    setIndicatorIndex(Number(e.target.id));
  };

  return (
    <StyledMainSlider>
      <Carousel
        indicatorIndex={indicatorIndex}
        setIndicatorIndex={setIndicatorIndex}
      >
        {mainSliders.map((item, index) => (
          <StyledMainSliderItem key={index}>
            <StyledSliderImage>
              <StyledSliderImageBackground
                image={`${process.env.REACT_APP_FILE_URL}/${item.Image}`}
              ></StyledSliderImageBackground>
              <img
                src={`${process.env.REACT_APP_FILE_URL}/${item.Image}`}
                alt={item.TitleEn}
              />
            </StyledSliderImage>
            <StyledSliderText>
              <StyledSliderTextBackground />
              <StyledSliderTitle>
                {language === "en"
                  ? item.TitleEn
                  : language === "ru"
                  ? item.TitleRu
                  : item.TitleTm}
              </StyledSliderTitle>
              <StyledSliderDescription>
                {language === "en"
                  ? item.DescriptionEn
                  : language === "ru"
                  ? item.DescriptionRu
                  : item.DescriptionTm}
              </StyledSliderDescription>
            </StyledSliderText>
          </StyledMainSliderItem>
        ))}
      </Carousel>
      <StyledCarouselIndicators>
        {mainSliders.map((item, index) => (
          <StyledCarouselIndicatorsItem
            key={index}
            id={index}
            indicator={indicatorIndex}
            onClick={handleIndicators}
          />
        ))}
      </StyledCarouselIndicators>
    </StyledMainSlider>
  );
};

export default MainSlider;
