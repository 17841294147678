import styled from "styled-components";
import { media } from "../../ui/media";

const StyledLegalInformationCenter = styled.div`
  padding: 30px 150px 23px;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.TABLET} {
    flex-direction: column;
    margin-bottom: 25px;
  }
  ${media.PHONE} {
  }
`;
const StyledTitle = styled.h2`
  margin-bottom: 23px;
  font-family: DM Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: #2c5d5b;
  ${media.TABLET} {
    margin-bottom: 20px;
    text-align: center;
  }
  ${media.PHONE} {
  }
`;
const StyledDescription = styled.p`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-decoration: underline;
`;

const StyledList = styled.div`
  width: 100%;
`;
const StyledListItem = styled.div`
  width: 100%;
  padding: 22px 40px 22px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:nth-child(odd) {
    background-color: #eff4fa;
  }
  &:nth-child(even) {
    background-color: #fcfcfc;
  }
  p {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    padding: 20px;
    p {
      width: 350px;
    }
    svg {
      width: 22px;
    }
  }
`;

const StyledButton = styled.button`
  width: 126px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 9px;
  border: 1px solid #2c5d5b;
  background: none;

  path {
    fill: #000000;
  }
  p {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: max-content;
    height: max-content;
    border: none;
    p {
      display: none;
    }
  }
`;

export {
  StyledLegalInformationCenter,
  StyledTitleContainer,
  StyledTitle,
  StyledDescription,
  StyledList,
  StyledListItem,
  StyledButton,
};
