import styled from "styled-components";
import { media } from "../../ui/media";

const StyledHeader = styled.div`
  width: 100%;
`;

const StyledHeaderInfo = styled.div`
  height: 52px;
  background: #2c5d5b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  p {
    font-family: DM Sans;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  svg {
    margin-right: 10px;
  }
  ${media.TABLET} {
    padding: 0 20px;
  }
  ${media.PHONE} {
    position: relative;
    width: 100%;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
  }
`;

const StyledInfo = styled.div`
  height: 37px;
  display: flex;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    justify-content: start;
  }
`;

const StyledClock = styled.div`
  display: flex;
  align-items: center;
  padding-right: 25px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: max-content;
    padding-right: 15px;
  }
`;

const StyledCalendar = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #417e7c;
  border-right: 1px solid #417e7c;
  padding: 0 18px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: max-content;
    padding: 0 15px;
  }
`;

const StyledPhone = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledLanguageContainer = styled.div`
  position: relative;

  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;

const StyledLanguage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    margin-left: 10px;
    margin-right: 5px;
  }
  svg {
    margin-right: 0;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    justify-content: start;
    margin-left: 15px;
    p {
      display: none;
    }
    svg {
      margin-left: 15px;
    }
  }
`;

const StyledLanguageDropdown = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  width: 100%;
  top: 37px;
  background-color: #fff;
  ${media.TABLET} {
    z-index: 1;
  }
  ${media.PHONE} {
    width: max-content;
    right: 0;
  }
`;

const StyledLanguageDropdownItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #417e7c;
  &:first-child {
    border-top: 1px solid #417e7c;
  }
  cursor: pointer;
  p {
    color: black;
  }
  &:hover {
    background-color: #417e7c;
    p {
      color: white;
    }
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: 60px;
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: #00000050;
    z-index: 2;
    p {
      display: none;
    }
  }
`;

const StyledFlag = styled.img`
  width: 30px;
  height: 20px;
`;

const StyledLogoContainer = styled.div`
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  ${media.TABLET} {
    padding: 0 20px;
  }
  ${media.PHONE} {
    height: max-content;
  }
`;

const StyledHeaderLogo = styled.div`
  cursor: pointer;
  img {
    width: 255px;
    height: 71px;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    img {
      width: 255px;
      height: 71px;
    }
  }
`;
const StyledSearchInput = styled.div`
  position: relative;
  input {
    width: 366px;
    height: 38px;
    padding: 10px;
    padding-right: 30px;
    border: 1px solid #cdcdcd;
    border-radius: 20px;
    text-align: center;
  }
  svg {
    top: 32%;
    right: 12px;
    position: absolute;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    input {
      display: none;
    }
    svg {
      display: none;
    }
  }
`;
const StyledYearLogo = styled.div`
  display: flex;
  align-items: center;
  p {
    width: 260px;
    font-family: DM Sans;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: -webkit-linear-gradient(#00403e, #00827c);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img {
    width: 89px;
    height: 88px;
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
    display: none;
  }
`;
const StyledMenuBar = styled.div`
  display: none;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    display: block;
    cursor: pointer;
    svg {
      width: 22px;
    }
  }
`;

const StyledHeaderNav = styled.nav`
  height: 70px;
  background: #2c5d5b;
  padding: 0 150px;
  div {
    color: #ffffff;
  }
  ul {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    li {
      cursor: pointer;
    }
    li:last-child {
      div {
        position: relative;
        width: 140px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: #e8fffe;
        font-size: 14px;
        line-height: 18px;
        color: #2c5d5b;
      }

      svg {
        margin-right: 11px;
      }
    }
  }
  ${media.TABLET} {
    padding: 0 20px;
    ul {
      font-size: 15px;
      line-height: 20px;
      li:last-child {
        width: max-content;
        height: 40px;
        padding: 5px;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledHeaderNews = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 150px;
  ${media.TABLET} {
    padding: 0 20px;
    justify-content: center;
  }
  ${media.PHONE} {
    background: #2c5d5b;
  }
`;

const StyledLastNews = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: #000000;
  border-right: 1px solid #d9d9d9;
  p {
    font-family: DM Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  p:last-child {
    margin: 0 45px;
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;
const StyledLastNewsTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
  cursor: pointer;
  p {
    font-family: DM Sans;
    font-weight: 500;
    font-size: 18px;
    line-height: 23.44px;
    background: -webkit-linear-gradient(#bbbbbb, #000000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  img {
    width: 25px;
    height: 29px;
    margin: 0 30px;
  }
  ${media.TABLET} {
    margin-left: 0px;
  }
  ${media.PHONE} {
    display: flex;
    align-items: space-between;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18.44px;
      text-align: center;
      background: -webkit-linear-gradient(rgb(248, 183, 5), rgb(255, 255, 255));
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      display: none;
    }
  }
`;

const StyledPreviousNews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 41px;
  margin-left: 42px;
  margin-right: 16px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  cursor: pointer;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    height: 31px;
    width: 31px;
  }
`;
const StyledNextNews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 41px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  cursor: pointer;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    height: 31px;
    width: 31px;
  }
`;

export {
  StyledHeader,
  StyledHeaderInfo,
  StyledInfo,
  StyledClock,
  StyledCalendar,
  StyledPhone,
  StyledLanguageContainer,
  StyledLanguage,
  StyledLanguageDropdown,
  StyledLanguageDropdownItem,
  StyledFlag,
  StyledMenuBar,
  StyledLogoContainer,
  StyledHeaderLogo,
  StyledSearchInput,
  StyledYearLogo,
  StyledHeaderNav,
  StyledHeaderNews,
  StyledLastNews,
  StyledLastNewsTitle,
  StyledPreviousNews,
  StyledNextNews,
};
