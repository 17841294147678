import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSearchedNews } from "../../services/newsApi";

const initialState = {
  results: {
    news: [],
  },
  error: null,
  status: "idle",
  totalPage: 1,
  totalEntries: 0,
};

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async ({ typeOfNewsID, searchedNews, limit, page }) => {
    return getSearchedNews(typeOfNewsID, searchedNews, limit, page);
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNews.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchNews.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results.news = payload.news;
      state.totalPage = payload.totalPage;
      state.totalEntries = payload.totalEntries;
    });
    builder.addCase(fetchNews.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default newsSlice.reducer;
