export const routes = {
  MAIN: "/",
  HABARLAR: "/habarlar",
  HABARLAR_INFO: "/habarlar/:id",
  MAGLUMATLAR: "/maglumatlar",
  TOLEGLER: "/tölegler",
  YANGYN_TOLEG: "/tölegler/ýangyn-howpsuzlyk-tölegler",
  MULTIMEDIA: "/multimediýa",
  SALGYLAR: "/salgylar",
  SAHSY_OTAG: "şahsy-otag",
  NOT_FOUND: "/*",
};
