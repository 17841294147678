import React from "react";
import { StyledOnlinePay } from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import PaymentTypes from "../../components/PaymentTypes/PaymentTypes";
const OnlinePay = () => {
  return (
    <StyledOnlinePay>
      <PageTitle title="Tölegler" />
      <PaymentTypes />
    </StyledOnlinePay>
  );
};

export default OnlinePay;
