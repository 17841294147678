import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMainSliders } from "../../services/mainSliderApi";

const initialState = {
  results: {
    mainSliders: [],
  },
  error: null,
  status: "idle",
};

export const fetchAllMainSliders = createAsyncThunk(
  "mainSlider/fetchAllMainSliders",
  async () => {
    return getAllMainSliders();
  }
);

const mainSliderSlice = createSlice({
  name: "mainSlider",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllMainSliders.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchAllMainSliders.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results = payload;
    });
    builder.addCase(fetchAllMainSliders.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default mainSliderSlice.reducer;
