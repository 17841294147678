import React, { useEffect } from "react";
import {
  StyledHabarlarDescription,
  StyledContainer,
  StyledMainContainer,
  StyledMainTitle,
  StyledDateContainer,
  StyledDateTitle,
  StyledViews,
  StyledImage,
  StyledDescription,
  StyledSideBar,
  StyledSideBarTitle,
  StyledSideBarItem,
  StyledSideBarItemImage,
  StyledSideBarItemContainer,
  StyledSideBarItemTitle,
  StyledSideBarItemDate,
} from "./styles";
import IconSelector from "../../ui/IconSelector";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getLanguage } from "../../store/selectors/languageSelectors";
import { formatDate } from "../formatDate";
import { getMoreWatchedNews } from "../../store/selectors/moreWatchedNewsSelectors";
import { fetchMoreWatchedNews } from "../../store/slices/moreWatchedNewsSlice";
import { updateViews } from "../../services/newsApi";
import { fetchNewsById } from "../../store/slices/newsByIdSlice";
import { getNewsById } from "../../store/selectors/newsByIdSelectors";

const HabarlarDescription = () => {
  const dispatch = useAppDispatch();
  const { id = "" } = useParams();
  const news = useAppSelector(getNewsById);
  const language = useAppSelector(getLanguage);
  const moreWatchedNews = useAppSelector(getMoreWatchedNews);

  useEffect(() => {
    dispatch(fetchMoreWatchedNews());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNewsById(id));
    updateViews(id);
  }, [dispatch, id]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page whenever the route changes
  }, [location]);

  return (
    <StyledHabarlarDescription>
      <StyledContainer>
        <StyledMainContainer>
          <StyledMainTitle>
            {language === "en"
              ? news.TitleEn
              : language === "ru"
              ? news.TitleRu
              : news.TitleTm}
          </StyledMainTitle>
          <StyledDateContainer>
            <StyledViews>
              <IconSelector id="views" />
              <p>{news.Views}</p>
            </StyledViews>
            <p>{formatDate(news.CreatedAt)}</p>
            <StyledDateTitle>
              {news.TypeOfNewsID === 1
                ? "Ministrligiň täzelikleri"
                : "Beýleki habarlar"}
            </StyledDateTitle>
          </StyledDateContainer>
          <StyledImage>
            <img
              src={`${process.env.REACT_APP_FILE_URL}/${news.Image}`}
              alt="newsImage"
            />
          </StyledImage>
          <StyledDescription
            dangerouslySetInnerHTML={{
              __html:
                language === "en"
                  ? news.DescriptionEn
                  : language === "ru"
                  ? news.DescriptionRu
                  : news.DescriptionTm,
            }}
          ></StyledDescription>
        </StyledMainContainer>
        <StyledSideBar>
          <StyledSideBarTitle>Köp okalan</StyledSideBarTitle>
          {moreWatchedNews.map((item) => (
            <StyledSideBarItem key={item.ID} to={"/habarlar/" + item.ID}>
              <StyledSideBarItemImage>
                <img
                  src={`${process.env.REACT_APP_FILE_URL}/${item.Image}`}
                  alt="newsImage"
                />
              </StyledSideBarItemImage>
              <StyledSideBarItemContainer>
                <StyledSideBarItemTitle>
                  {language === "en"
                    ? item.TitleEn
                    : language === "ru"
                    ? item.TitleRu
                    : item.TitleTm}
                </StyledSideBarItemTitle>
                <StyledSideBarItemDate>
                  {formatDate(item.CreatedAt)}
                </StyledSideBarItemDate>
              </StyledSideBarItemContainer>
            </StyledSideBarItem>
          ))}
        </StyledSideBar>
      </StyledContainer>
    </StyledHabarlarDescription>
  );
};

export default HabarlarDescription;
