import styled from "styled-components";
import { media } from "../../ui/media";

const StyledPhotogallery = styled.div`
  padding: 29px 100px 48px;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: max-content max-content;
  grid-gap: 20px;
  justify-content: center;
  img {
    &:first-child {
      width: 834px;
      height: 895px;
      border-radius: 37px;
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }
    &:nth-child(2) {
      width: 388px;
      height: 438px;
      border-radius: 33px;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    &:nth-child(3) {
      width: 388px;
      height: 438px;
      border-radius: 33px;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
  ${media.TABLET} {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    img {
      &:first-child {
        width: 340px;
        height: 370px;
        border-radius: 15px;
      }
      &:nth-child(2) {
        width: 340px;
        height: 370px;
        border-radius: 15px;
      }
      &:nth-child(3) {
        width: 340px;
        height: 370px;
        border-radius: 15px;
      }
    }
  }
  ${media.PHONE} {
    flex-direction: column;
  }
`;

export { StyledPhotogallery };
