import React from "react";
import {
  StyledMiaDepartments,
  StyledTitle,
  StyledContainer,
  StyledLocation,
} from "./styles";

const MiaDepartments = () => {
  return (
    <StyledMiaDepartments>
      <StyledTitle>Içeri işler ministrligiň edaralary</StyledTitle>
      <StyledContainer>
        <StyledLocation>
          <p>Aşgabat</p>
        </StyledLocation>
        <StyledLocation>
          <p>Arkadag Şäheri</p>
        </StyledLocation>
        <StyledLocation>
          <p>Lebap welaýaty</p>
        </StyledLocation>
        <StyledLocation>
          <p>Balkan welaýaty</p>
        </StyledLocation>
        <StyledLocation>
          <p>Ahal welaýaty</p>
        </StyledLocation>
        <StyledLocation>
          <p>Dasoguz welaýaty</p>
        </StyledLocation>
        <StyledLocation>
          <p>Mary welaýaty</p>
        </StyledLocation>
      </StyledContainer>
    </StyledMiaDepartments>
  );
};

export default MiaDepartments;
