import React from "react";
import {
  StyledSalgylarInformation,
  StyledContainer,
  StyledTitle,
  StyledDescription,
  StyledImage,
} from "./styles";
import IconSelector from "../../ui/IconSelector";

import salgylarimage from "../../assets/salgylar.webp";

const SalgylarInformation = () => {
  return (
    <StyledSalgylarInformation>
      <StyledContainer>
        <StyledTitle>Habarlaşmak üçin</StyledTitle>

        <StyledDescription>
          <IconSelector id="location" />
          <p>Türkmenistan , Ashgabat</p>
        </StyledDescription>
        <StyledDescription>
          <IconSelector id="phone-location" />
          <p>(123) 456-7890</p>
        </StyledDescription>
        <StyledDescription>
          <IconSelector id="phone-location" />
          <p>(123) 456-7890</p>
        </StyledDescription>
        <StyledDescription>
          <IconSelector id="fax" />
          <p>(123) 456-7890</p>
        </StyledDescription>
      </StyledContainer>
      <StyledImage>
        <img src={salgylarimage} alt="salgylarimage" />
      </StyledImage>
    </StyledSalgylarInformation>
  );
};

export default SalgylarInformation;
