import styled from "styled-components";
import { media } from "../../ui/media";

const StyledOnlinePay = styled.div`
  margin-bottom: 80px;
  ${media.TABLET} {
    margin-bottom: 5px;
  }
  ${media.PHONE} {
    margin-bottom: 10px;
  }
`;

export { StyledOnlinePay };
