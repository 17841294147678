import React from "react";
import tiimbuilding from "../../assets/tiimbuilding.webp";
import shevron from "../../assets/shevron.webp";

import {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterImage,
  StyledTiimLogo,
  StyledTiimBuilding,
  StyledFooterNav,
  StyledNavList,
  StyledFooterBottom,
} from "./styles";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterContainer>
        <StyledFooterImage>
          <StyledTiimLogo>
            <img src={shevron} alt="shevron" />
            <p>Türkmenistanyň içeri işler ministrligi</p>
          </StyledTiimLogo>
          <StyledTiimBuilding>
            <img src={tiimbuilding} alt="tiimbuilding" />
          </StyledTiimBuilding>
        </StyledFooterImage>
        <StyledFooterNav>
          <StyledNavList>
            <h2>Bölümlerimiz:</h2>

            <a href="/habarlar">Habarlar</a>

            <a href="/salgylar">Salgylar</a>

            <a href="/multimediýa">Multimediýa</a>

            <a href="/bölümlerimiz">Bölümlerimiz</a>
          </StyledNavList>
          <StyledNavList>
            <h2>Onlaýn hyzmatlar:</h2>

            <a href="/ýangyn-howpsuzlyk-tölegler">Ýangyn howpsuzlyk tölegler</a>

            <a href="/p-ý-g-g-tölegler">P.Ý.G.G Tölegler</a>

            <a href="/ýerli-wekil-jerimeler">Ýerli wekil jerimeler</a>
          </StyledNavList>
          <StyledNavList>
            <h2>Telefon belgilerimiz:</h2>

            <p>+99312 38-89-11</p>

            <p>tiim-metbugat@sanly.tm</p>
          </StyledNavList>
        </StyledFooterNav>
      </StyledFooterContainer>
      <StyledFooterBottom>
        <p>© 2024 Türkmenistanyň Içeri işler ministrligi</p>
      </StyledFooterBottom>
    </StyledFooter>
  );
};

export default Footer;
