import { createSlice } from "@reduxjs/toolkit";
import turkmenFlag from "../../assets/tm.webp";
import russianFlag from "../../assets/ru.webp";
import englishFlag from "../../assets/en.webp";

const initialState = {
  isOpen: false,
  language: "tm",
  languageOptions: [
    { name: "Türkmen", flag: turkmenFlag, id: "tm" },
    { name: "Русский", flag: russianFlag, id: "ru" },
    { name: "English", flag: englishFlag, id: "en" },
  ],
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    openLanguageDropDown: (state) => {
      state.isOpen = true;
    },
    closeLanguageDropDown: (state) => {
      state.isOpen = false;
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { openLanguageDropDown, closeLanguageDropDown, changeLanguage } =
  languageSlice.actions;
export default languageSlice.reducer;
