import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentMinistryNews } from "../../services/newsApi";

const initialState = {
  results: {
    recentOtherNews: [],
  },
  error: null,
  status: "idle",
};

export const fetchRecentOtherNews = createAsyncThunk(
  "recentOtherNews/fetchRecentOtherNews",
  async () => {
    return getRecentMinistryNews();
  }
);

const recentOtherNewsSlice = createSlice({
  name: "recentOtherNews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRecentOtherNews.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchRecentOtherNews.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results.recentOtherNews = payload;
    });
    builder.addCase(fetchRecentOtherNews.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default recentOtherNewsSlice.reducer;
