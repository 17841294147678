import styled from "styled-components";
import { media } from "../../ui/media";

const StyledMiaDepartments = styled.div`
  padding: 0 150px;

  ${media.TABLET} {
    padding: 0 20px;
  }
  ${media.PHONE} {
  }
`;

const StyledTitle = styled.h2`
  font-family: DM Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 37px;
  ${media.TABLET} {
    text-align: center;
  }
  ${media.PHONE} {
    font-size: 20px;
    line-height: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
`;
const StyledContainer = styled.div`
  display: flex;
  gap: 15px;

  align-items: center;
  margin-bottom: 20px;
  ${media.TABLET} {
    overflow-x: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  ${media.PHONE} {
  }
`;
const StyledLocation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #cccccc;
  cursor: pointer;
  p {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;

export { StyledMiaDepartments, StyledTitle, StyledContainer, StyledLocation };
