import styled from "styled-components";
import { media } from "../../ui/media";

const StyledSalgylarInformation = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 70px;
  padding: 42px 150px 33px;
  ${media.TABLET} {
    padding: 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  ${media.PHONE} {
  }
`;

const StyledContainer = styled.div`
  ${media.TABLET} {
  }
  ${media.PHONE} {
    padding: 10px;
    border-radius: 10px;
    background-color: #00000060;
    z-index: 1;
  }
`;

const StyledTitle = styled.h2`
  margin-bottom: 23px;
  font-family: DM Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #ffffff;
  }
`;
const StyledDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
  p {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    gap: 10px;
    margin-bottom: 15px;
    p {
      font-family: Mulish;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #ffffff;
    }
    path {
      fill: #ffffff;
    }
  }
`;

const StyledImage = styled.div`
  img {
    width: 852px;
    height: 403px;
    border-radius: 14px;
  }
  ${media.TABLET} {
    img {
      width: 450px;
      height: 220px;
    }
  }
  ${media.PHONE} {
    position: absolute;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
  }
`;

export {
  StyledSalgylarInformation,
  StyledContainer,
  StyledTitle,
  StyledDescription,
  StyledImage,
};
