import React, { useEffect, useState, Children, cloneElement } from "react";
import {
  StyledCarousel,
  StyledCarouselButton,
  StyledCarouselItemsContainer,
  StyledCarouselWindow,
} from "./styled";
import IconSelector from "../ui/IconSelector";

const Carousel = ({ children, indicatorIndex, setIndicatorIndex }) => {
  const [pages, setPages] = useState([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(() => {
      const maxOffset = -100 * indicatorIndex;
      return Math.max(maxOffset);
    });
  }, [indicatorIndex, pages]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset((currentOffset) => {
        const newOffset = currentOffset - 100;
        const maxOffset = -100 * (pages.length - 1);

        if (newOffset < maxOffset) {
          setIndicatorIndex(0);
          return 0;
        }
        setIndicatorIndex(newOffset / -100);
        return Math.max(newOffset, maxOffset);
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [offset, pages.length, setIndicatorIndex]);

  const handlePrevClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset + 100;
      setIndicatorIndex(newOffset / -100);
      return Math.min(newOffset, 0 * -100);
    });
  };
  const handleNextClick = () => {
    setOffset((currentOffset) => {
      const newOffset = currentOffset - 100;
      const maxOffset = -100 * (pages.length - 1);

      if (newOffset < maxOffset) {
        setIndicatorIndex(0);
        return 0;
      }
      setIndicatorIndex(newOffset / -100);
      return Math.max(newOffset, maxOffset);
    });
  };

  useEffect(() => {
    setPages(
      Children.map(children, (child) => {
        return cloneElement(child, {
          style: {
            minHeight: "100%",
            minWidth: "100%",
          },
        });
      })
    );
  }, [children]);

  return (
    <StyledCarousel>
      <StyledCarouselButton onClick={handlePrevClick}>
        <IconSelector id="headerPrevius" />
      </StyledCarouselButton>
      <StyledCarouselWindow>
        <StyledCarouselItemsContainer
          style={{
            transform: `translateX(${offset}%)`,
          }}
        >
          {pages}
        </StyledCarouselItemsContainer>
      </StyledCarouselWindow>
      <StyledCarouselButton onClick={handleNextClick}>
        <IconSelector id="headerNext" />
      </StyledCarouselButton>
    </StyledCarousel>
  );
};

export default Carousel;
