import styled from "styled-components";
import { media } from "../../ui/media";
import galkanpaymobile from "../../assets/galkanpay-background-mobile.webp";
import galkanpayBackground from "../../assets/galkanpay-background.webp";

const StyledGalkanPay = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: 1fr 700px;
  justify-content: space-between;
  margin-bottom: 89px;
  background-image: url(${galkanpayBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /*   
  background: linear-gradient(
    90deg,
    #fdf7ff 0%,
    #fcfff5 27%,
    #f7fee8 29.5%,
    #b6fffc 100%
  ); */
  ${media.TABLET} {
    display: flex;
    height: max-content;
    background-image: url(${galkanpaymobile});
    background-size: 100% 100%;
  }
  ${media.PHONE} {
    background-image: url(${galkanpaymobile});
    background-size: 100% 100%;
  }
`;

const StyledGalkanPayImage = styled.div``;

const StyledGalkanPayContainer = styled.div`
  width: 700px;
  padding: 78px 0 0 100px;
  place-self: center;
  @media only screen and (max-width: 1405px) {
    width: 600px;
  }
  ${media.TABLET} {
    width: 100%;
    padding: 20px;
    margin-left: 20px;
  }
  ${media.PHONE} {
    margin-left: 0px;
  }
`;
const StyledGalkanPayTitle = styled.h2`
  margin-bottom: 34px;
  font-family: DM Sans;
  font-size: 59px;
  font-weight: 700;
  line-height: 76px;
  ${media.TABLET} {
    margin-bottom: 14px;
    font-size: 30px;

    line-height: 30px;
    text-align: left;
  }
  ${media.PHONE} {
    margin-bottom: 14px;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
  }
`;
const StyledGalkanPayApplication = styled.div`
  display: flex;
  gap: 7px;
  margin-bottom: 25px;
  img {
    width: 159px;
    height: 57px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #00000040;
  }
  ${media.TABLET} {
  }
  ${media.PHONE} {
    flex-direction: column;
    margin-left: 12%;
    margin-bottom: 15px;
    img {
      width: 100px;
      height: 40px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #00000040;
    }
  }
`;
const StyledGalkanPayDescription = styled.p`
  width: 377px;
  margin-bottom: 33px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: 180px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }
`;

export {
  StyledGalkanPay,
  StyledGalkanPayContainer,
  StyledGalkanPayTitle,
  StyledGalkanPayApplication,
  StyledGalkanPayDescription,
  StyledGalkanPayImage,
};
