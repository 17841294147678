import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentJournals } from "../../services/journalApi";

const initialState = {
  results: {
    journals: [],
  },
  error: null,
  status: "idle",
};

export const fetchRecentJournals = createAsyncThunk(
  "recentJournal/fetchRecentJournals",
  async () => {
    return getRecentJournals();
  }
);

const recentJournalSlice = createSlice({
  name: "recentJournal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRecentJournals.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchRecentJournals.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results = payload;
    });
    builder.addCase(fetchRecentJournals.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default recentJournalSlice.reducer;
