import React, { useEffect } from "react";
import {
  StyledMinistryNews,
  StyledTitleContainer,
  StyledTitle,
  StyledTitleLine,
  StyledTitleButton,
  StyledNewsContainer,
  StyledNewsItem,
  StyledNewsImage,
  StyledNewsViewsContainer,
  StyledNewsViews,
  StyledNewsTitle,
} from "./styles";

import IconSelector from "../../ui/IconSelector";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { getRecentMinistryNews } from "../../store/selectors/recentMinistryNewsSelectors";
import { fetchRecentMinistryNews } from "../../store/slices/recentMinistryNewsSlice";
import { getLanguage } from "../../store/selectors/languageSelectors";
import { formatDate } from "../formatDate";
const MinistryNews = () => {
  const dispatch = useAppDispatch();

  const language = useAppSelector(getLanguage);

  useEffect(() => {
    dispatch(fetchRecentMinistryNews());
  }, [dispatch]);
  const recentMinistryNews = useAppSelector(getRecentMinistryNews);

  return (
    <StyledMinistryNews>
      <StyledTitleContainer>
        <StyledTitle>Ministrligiň täzelikleri</StyledTitle>
        <StyledTitleLine>
          <IconSelector id="title-line" />
        </StyledTitleLine>
        <StyledTitleButton>Ählisini görmek</StyledTitleButton>
      </StyledTitleContainer>
      <StyledNewsContainer>
        {recentMinistryNews.map((item) => (
          <StyledNewsItem key={item.ID} to={"/habarlar/" + item.ID}>
            <StyledNewsImage>
              <img
                src={`${process.env.REACT_APP_FILE_URL}/${item.Image}`}
                alt="newsimage"
              />
            </StyledNewsImage>
            <StyledNewsViewsContainer>
              <StyledNewsViews>
                <IconSelector id="views" />
                <p>{item.Views}</p>
              </StyledNewsViews>

              <p>{formatDate(item.CreatedAt)}</p>
            </StyledNewsViewsContainer>
            <StyledNewsTitle>
              {language === "en"
                ? item.TitleTm
                : language === "ru"
                ? item.TitleRu
                : item.TitleTm}
            </StyledNewsTitle>
          </StyledNewsItem>
        ))}
      </StyledNewsContainer>
    </StyledMinistryNews>
  );
};

export default MinistryNews;
