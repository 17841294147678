import styled from "styled-components";
import { media } from "../../ui/media";

const StyledMainSlider = styled.div``;

const StyledMainSliderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const StyledSliderImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    max-width: 100%;

    z-index: 1;
  }
`;

const StyledSliderImageBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: brightness(70%) contrast(70%) grayscale(70%);
  opacity: 0.7;
`;

const StyledSliderText = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 121px;
  padding: 20px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: DM Sans;
  color: #ffffff;
  ${media.TABLET} {
    height: max-content;
    padding: 10px;
  }
  ${media.PHONE} {
    height: max-content;
    padding: 10px;
  }
`;
const StyledSliderTextBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(#000000, #7a7a7a, #ffffff);
  opacity: 50%;
  z-index: 1;
`;
const StyledSliderTitle = styled.h2`
  position: relative;
  width: 1053px;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 12px;
  z-index: 2;
  ${media.TABLET} {
    width: 100%;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  ${media.PHONE} {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 0px;
  }
`;
const StyledSliderDescription = styled.p`
  width: 1053px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  z-index: 2;
  ${media.TABLET} {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }
  ${media.PHONE} {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
  }
`;
const StyledCarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin: 28px 0;
`;

const StyledCarouselIndicatorsItem = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 15px;
  opacity: 50%;
  background-color: #8d8d8d;
  cursor: pointer;
  &:nth-child(${(props) => props.indicator + 1}) {
    width: 45px;
    background-color: #2c5d5b;
    opacity: 100%;
  }
  &:last-child {
    margin: 0;
  }
`;

export {
  StyledMainSlider,
  StyledMainSliderItem,
  StyledSliderDescription,
  StyledSliderImage,
  StyledSliderImageBackground,
  StyledSliderText,
  StyledSliderTextBackground,
  StyledSliderTitle,
  StyledCarouselIndicators,
  StyledCarouselIndicatorsItem,
};
