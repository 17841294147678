import React from "react";
import { Routes, Route } from "react-router-dom";
import MainTemplate from "../pages/MainTemplate/MainTemplate";
import MainPage from "../pages/MainPage/MainPage";
import { routes } from "../routes/routes";
import NotFound from "../pages/NotFound/NotFound";
import Habarlar from "../pages/Habarlar/Habarlar";
import Maglumatlar from "../pages/Maglumatlar/Maglumatlar";
import OnlinePay from "../pages/OnlinePay/OnlinePay";
import Multimedia from "../pages/Multimedia/Multimedia";
import Salgylar from "../pages/Salgylar/Salgylar";
import HabarlarInfo from "../pages/HabarlarInfo/HabarlarInfo";
import YangynPay from "../pages/YangynPay/YangynPay";
import Account from "../pages/Account/Account";

const AppRouter = () => {
  return (
    <Routes>
      <Route path={routes.HOME} element={<MainTemplate />}>
        <Route index element={<MainPage />}></Route>
        <Route path={routes.HABARLAR} element={<Habarlar />}></Route>
        <Route path={routes.HABARLAR_INFO} element={<HabarlarInfo />}></Route>
        <Route path={routes.MAGLUMATLAR} element={<Maglumatlar />}></Route>
        <Route path={routes.MULTIMEDIA} element={<Multimedia />}></Route>
        <Route path={routes.SALGYLAR} element={<Salgylar />}></Route>
        <Route path={routes.TOLEGLER} element={<OnlinePay />}></Route>
        <Route path={routes.YANGYN_TOLEG} element={<YangynPay />}></Route>
        <Route path={routes.SAHSY_OTAG} element={<Account />}></Route>
        <Route path={routes.NOT_FOUND} element={<NotFound />}></Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
