import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMinistryNews } from "../../services/newsApi";

const initialState = {
  results: {
    ministryNews: [],
  },
  error: null,
  status: "idle",
};

export const fetchMinistryNews = createAsyncThunk(
  "ministryNews/fetchMinistryNews",
  async () => {
    return getAllMinistryNews();
  }
);

const ministryNewsSlice = createSlice({
  name: "ministryNews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMinistryNews.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchMinistryNews.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.results.ministryNews = payload;
    });
    builder.addCase(fetchMinistryNews.rejected, (state, { payload }) => {
      state.status = "error";
      state.error = payload;
    });
  },
});

export default ministryNewsSlice.reducer;
