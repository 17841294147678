import styled from "styled-components";

const StyledHabarlar = styled.div`
  position: relative;
  padding: 44px 100px 76px;
`;

const StyledHabarlarHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 50px;
`;

const StyledHabarlarButton = styled.button`
  padding: 10px 20px;
  border-bottom: 2px solid ${({ isActive }) => (isActive ? "#2c5d5b" : "white")};
  background: none;
  color: #2c5d5b;
  font-size: 20px;
  font-weight: 700;
  transition: all 0.6s ease;

  &:hover {
    border-radius: 10px;
    background-color: #2c5d5b;
    color: white;
  }
`;

const StyledSearchInput = styled.div`
  position: relative;
  input {
    width: 366px;
    height: 38px;
    padding: 10px;
    padding-right: 30px;
    border: 1px solid #cdcdcd;
    border-radius: 20px;
    text-align: center;
  }
  svg {
    top: 25%;
    right: 15px;
    position: absolute;
  }
`;

const StyledHabarlarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  place-items: center;
  grid-gap: 28px;
  margin-bottom: 40px;
`;
const StyledModalContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export {
  StyledHabarlar,
  StyledHabarlarHeader,
  StyledButtonContainer,
  StyledHabarlarButton,
  StyledSearchInput,
  StyledHabarlarContainer,
  StyledModalContainer,
};
