import styled from "styled-components";
import { media } from "../../ui/media";

const StyledPaymentTypes = styled.div`
  padding: 60px 150px;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;

const StyledPaymentTypesTitle = styled.h2`
  font-family: DM Sans;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  margin-bottom: 21px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    font-size: 20px;
    line-height: 20px;
  }
`;

const StyledPaymentTypesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  @media only screen and (max-width: 1779px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  @media only screen and (max-width: 1539px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media only screen and (max-width: 1433px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  grid-gap: 20px;

  justify-content: space-between;
  ${media.TABLET} {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    overflow-x: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  ${media.PHONE} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledPaymentTypesItem = styled.div`
  position: relative;
  width: 379px;
  height: 204px;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.65s;
  place-self: center;
  img {
    transition: all 1.2s;
  }
  &:hover {
    margin: -20px;
    width: 399px;
    height: 224px;

    .fire_image {
      width: 100px;
      height: 120px;
      margin-right: 20px;
    }

    .policecar_image {
      width: 149px;
      height: 122px;
      margin-right: 20px;
    }

    .policemen_image {
      width: 132px;
      height: 127px;
      margin-right: 20px;
    }
  }
  ${media.TABLET} {
    &:hover {
      margin: 0px;
      width: 379px;
      height: 204px;
      .fire_image {
        width: 60px;
        height: 80px;
        margin-right: 10px;
      }

      .policecar_image {
        width: 109px;
        height: 82px;
        margin-right: 10px;
      }

      .policemen_image {
        width: 92px;
        height: 87px;
        margin-right: 10px;
      }
    }
  }
  ${media.PHONE} {
    width: 340px;
    height: 204px;

    &:hover {
      margin: 0px;
      width: 340px;
      height: 204px;
      .fire_image {
        width: 60px;
        height: 80px;
        margin-right: 10px;
      }

      .policecar_image {
        width: 109px;
        height: 82px;
        margin-right: 10px;
      }

      .policemen_image {
        width: 92px;
        height: 87px;
        margin-right: 10px;
      }
    }
  }
`;
const StyledImage = styled.div`
  .fire_image {
    width: 60px;
    height: 80px;
    margin-right: 10px;
  }

  .policecar_image {
    width: 109px;
    height: 82px;
    margin-right: 10px;
  }

  .policemen_image {
    width: 92px;
    height: 87px;
    margin-right: 10px;
  }
`;
const StyledPaymentTypesItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.h2`
  width: 215px;
  font-family: DM Sans;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;

  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: 180px;
    font-size: 24px;
    line-height: 31px;
  }
`;

const StyledPaymentTypesItemButton = styled.button`
  position: absolute;
  bottom: 17px;
  right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  p {
    margin-right: 4px;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #00329b;
  }
`;

export {
  StyledPaymentTypes,
  StyledPaymentTypesTitle,
  StyledPaymentTypesContainer,
  StyledPaymentTypesItem,
  StyledPaymentTypesItemContainer,
  StyledImage,
  StyledTitle,
  StyledPaymentTypesItemButton,
};
