import React from "react";

import { createPages } from "../../components/pagesCreator";

import {
  StyledNavigationButton,
  StyledNumberButton,
  StyledNumberButtonList,
  StyledPageControl,
} from "./styles";
import IconSelector from "../../ui/IconSelector";

const PageControl = ({
  handlePrevPage,
  handlePage,
  handleNextPage,
  totalPage,
  currentPage,
}) => {
  const ArrayOfTotalPage = [];

  createPages(ArrayOfTotalPage, totalPage, currentPage);
  return (
    <StyledPageControl>
      <StyledNavigationButton type="button" onClick={handlePrevPage}>
        <IconSelector id="headerPrevius" />
      </StyledNavigationButton>
      <StyledNumberButtonList>
        {ArrayOfTotalPage.map((item) => (
          <StyledNumberButton
            isActive={currentPage === item}
            key={item}
            onClick={() => handlePage(item)}
          >
            {item}
          </StyledNumberButton>
        ))}
      </StyledNumberButtonList>

      <StyledNavigationButton type="button" onClick={handleNextPage}>
        <IconSelector id="headerNext" />
      </StyledNavigationButton>
    </StyledPageControl>
  );
};

export default PageControl;
