import { configureStore } from "@reduxjs/toolkit";
import burgerMenuSlice from "./slices/burgerMenuSlice";
import languageSlice from "./slices/languageSlice";
import mainSliderSlice from "./slices/mainSliderSlice";
import ministryNewsSlice from "./slices/ministryNewsSlice";
import recentMinistryNewsSlice from "./slices/recentMinistryNewsSlice";
import recentOtherNewsSlice from "./slices/recentOtherNewsSlice";
import recentJournalSlice from "./slices/recentJournalSlice";
import newsByIdSlice from "./slices/newsByIdSlice";
import moreWatchedNewsSlice from "./slices/moreWatchedNewsSlice";
import newsSlice from "./slices/newsSlice";

const store = configureStore({
  reducer: {
    burgerMenu: burgerMenuSlice,
    language: languageSlice,
    mainSlider: mainSliderSlice,
    ministryNews: ministryNewsSlice,
    recentMinistryNews: recentMinistryNewsSlice,
    recentOtherNews: recentOtherNewsSlice,
    recentJournal: recentJournalSlice,
    newsById: newsByIdSlice,
    moreWatchedNews: moreWatchedNewsSlice,
    news: newsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
