import React from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledPaymentTypes,
  StyledPaymentTypesTitle,
  StyledPaymentTypesContainer,
  StyledPaymentTypesItem,
  StyledPaymentTypesItemContainer,
  StyledImage,
  StyledTitle,
  StyledPaymentTypesItemButton,
} from "./styles";
import fire from "../../assets/fire.webp";
import policecar from "../../assets/policecar.webp";
import policemen from "../../assets/policemen.webp";
import IconSelector from "../../ui/IconSelector";
const PaymentTypes = () => {
  const navigate = useNavigate();

  const handlePaymentClick = () => {
    navigate("/tölegler/ýangyn-howpsuzlyk-tölegler");
  };
  return (
    <StyledPaymentTypes>
      <StyledPaymentTypesTitle>Töleg görnüşleri </StyledPaymentTypesTitle>
      <StyledPaymentTypesContainer>
        <StyledPaymentTypesItem onClick={handlePaymentClick}>
          <StyledPaymentTypesItemContainer className="fire">
            <StyledImage>
              <img className="fire_image" src={fire} alt="fire" />
            </StyledImage>
            <StyledTitle>Yangyn howpsuzlyk tölegler</StyledTitle>
          </StyledPaymentTypesItemContainer>
          <StyledPaymentTypesItemButton>
            <p>Giňişleýin</p>
            <IconSelector id="next" />
          </StyledPaymentTypesItemButton>
        </StyledPaymentTypesItem>
        <StyledPaymentTypesItem onClick={handlePaymentClick}>
          <StyledPaymentTypesItemContainer className="policecar">
            <StyledImage>
              <img
                className="policecar_image"
                src={policecar}
                alt="policecar"
              />
            </StyledImage>
            <StyledTitle>P.Ý.G.G Tölegler</StyledTitle>
          </StyledPaymentTypesItemContainer>
          <StyledPaymentTypesItemButton>
            <p>Giňişleýin</p>
            <IconSelector id="next" />
          </StyledPaymentTypesItemButton>
        </StyledPaymentTypesItem>
        <StyledPaymentTypesItem onClick={handlePaymentClick}>
          <StyledPaymentTypesItemContainer className="policemen">
            <StyledImage>
              <img
                className="policemen_image"
                src={policemen}
                alt="policemen"
              />
            </StyledImage>
            <StyledTitle>Ýerli wekil jerimeler</StyledTitle>
          </StyledPaymentTypesItemContainer>
          <StyledPaymentTypesItemButton>
            <p>Giňişleýin</p>
            <IconSelector id="next" />
          </StyledPaymentTypesItemButton>
        </StyledPaymentTypesItem>
      </StyledPaymentTypesContainer>
    </StyledPaymentTypes>
  );
};

export default PaymentTypes;
