import React from "react";
import {
  StyledGovernmentWebsites,
  StyledTitleContainer,
  StyledTitle,
  StyledTitleLine,
  StyledTitleButton,
  StyledGovernmentWebsitesContainer,
  StyledGovernmentWebsitesItem,
  StyledItemImage,
  StyledItemTitleContainer,
  StyledItemTitle,
  StyledItemLink,
} from "./styles";
import demiryollar from "../../assets/demiryollar.webp";
import IconSelector from "../../ui/IconSelector";

const GovernmentWebsites = () => {
  return (
    <StyledGovernmentWebsites>
      <StyledTitleContainer>
        <StyledTitle>Döwlet web sahypalary</StyledTitle>
        <StyledTitleLine>
          <IconSelector id="title-line" />
        </StyledTitleLine>
        <StyledTitleButton>Ählisini görmek</StyledTitleButton>
      </StyledTitleContainer>
      <StyledGovernmentWebsitesContainer>
        <StyledGovernmentWebsitesItem>
          <StyledItemImage>
            <img src={demiryollar} alt="demiryollar" />
          </StyledItemImage>
          <StyledItemTitleContainer>
            <StyledItemTitle>
              Demir ýollar açyk görnüşli paýdarlar jemgyýeti
            </StyledItemTitle>
            <StyledItemLink href="https:railway.com.tm">
              https:railway.com.tm
            </StyledItemLink>
          </StyledItemTitleContainer>
        </StyledGovernmentWebsitesItem>
        <StyledGovernmentWebsitesItem>
          <StyledItemImage>
            <img src={demiryollar} alt="demiryollar" />
          </StyledItemImage>
          <StyledItemTitleContainer>
            <StyledItemTitle>
              Demir ýollar açyk görnüşli paýdarlar jemgyýeti
            </StyledItemTitle>
            <StyledItemLink href="https:railway.com.tm">
              https:railway.com.tm
            </StyledItemLink>
          </StyledItemTitleContainer>
        </StyledGovernmentWebsitesItem>
        <StyledGovernmentWebsitesItem>
          <StyledItemImage>
            <img src={demiryollar} alt="demiryollar" />
          </StyledItemImage>
          <StyledItemTitleContainer>
            <StyledItemTitle>
              Demir ýollar açyk görnüşli paýdarlar jemgyýeti
            </StyledItemTitle>
            <StyledItemLink href="https:railway.com.tm">
              https:railway.com.tm
            </StyledItemLink>
          </StyledItemTitleContainer>
        </StyledGovernmentWebsitesItem>
        <StyledGovernmentWebsitesItem>
          <StyledItemImage>
            <img src={demiryollar} alt="demiryollar" />
          </StyledItemImage>
          <StyledItemTitleContainer>
            <StyledItemTitle>
              Demir ýollar açyk görnüşli paýdarlar jemgyýeti
            </StyledItemTitle>
            <StyledItemLink href="https:railway.com.tm">
              https:railway.com.tm
            </StyledItemLink>
          </StyledItemTitleContainer>
        </StyledGovernmentWebsitesItem>
        <StyledGovernmentWebsitesItem>
          <StyledItemImage>
            <img src={demiryollar} alt="demiryollar" />
          </StyledItemImage>
          <StyledItemTitleContainer>
            <StyledItemTitle>
              Demir ýollar açyk görnüşli paýdarlar jemgyýeti
            </StyledItemTitle>
            <StyledItemLink href="https:railway.com.tm">
              https:railway.com.tm
            </StyledItemLink>
          </StyledItemTitleContainer>
        </StyledGovernmentWebsitesItem>
        <StyledGovernmentWebsitesItem>
          <StyledItemImage>
            <img src={demiryollar} alt="demiryollar" />
          </StyledItemImage>
          <StyledItemTitleContainer>
            <StyledItemTitle>
              Demir ýollar açyk görnüşli paýdarlar jemgyýeti
            </StyledItemTitle>
            <StyledItemLink href="https:railway.com.tm">
              https:railway.com.tm
            </StyledItemLink>
          </StyledItemTitleContainer>
        </StyledGovernmentWebsitesItem>
      </StyledGovernmentWebsitesContainer>
    </StyledGovernmentWebsites>
  );
};

export default GovernmentWebsites;
