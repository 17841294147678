import styled from "styled-components";
import { media } from "../../ui/media";

const StyledGovernmentWebsites = styled.div`
  padding: 0 150px 74px;
  display: flex;
  flex-direction: column;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 36px;
  ${media.TABLET} {
    margin-top: 0px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  ${media.PHONE} {
  }
`;
const StyledTitle = styled.h2`
  font-family: DM Sans;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #181818;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    font-size: 20px;
    line-height: 20px;
  }
`;
const StyledTitleLine = styled.div`
  width: 673px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;
const StyledTitleButton = styled.button`
  width: 163px;
  height: 41px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  background: none;
  opacity: 0px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: max-content;
    height: max-content;
    padding: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;

const StyledGovernmentWebsitesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

  grid-gap: 24px;
  place-items: center;
  ${media.TABLET} {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  ${media.PHONE} {
  }
`;

const StyledGovernmentWebsitesItem = styled.div`
  width: 398px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 31px;
  border: 1px solid #d9d9d9;
  background-color: #f8f8f8;
  place-self: center;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    padding: 10px;
  }
`;
const StyledItemImage = styled.div`
  img {
    width: 120px;
    height: 67px;
  }
`;
const StyledItemTitleContainer = styled.div`
  width: 225px;
  height: 73px;
  display: flex;
  flex-direction: column;
`;
const StyledItemTitle = styled.h3`
  margin-bottom: 15px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;
const StyledItemLink = styled.a`
  text-decoration: none;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: center;
`;

export {
  StyledGovernmentWebsites,
  StyledTitleContainer,
  StyledTitle,
  StyledTitleLine,
  StyledTitleButton,
  StyledGovernmentWebsitesContainer,
  StyledGovernmentWebsitesItem,
  StyledItemImage,
  StyledItemTitleContainer,
  StyledItemTitle,
  StyledItemLink,
};
