import styled from "styled-components";
import { media } from "../ui/media";

const StyledCarousel = styled.div`
  position: relative;
  height: 477px;
  width: 100%;
  display: flex;
  align-items: center;
  ${media.TABLET} {
    height: 377px;
  }
  ${media.PHONE} {
    height: 220px;
  }
`;
const StyledCarouselWindow = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
const StyledCarouselItemsContainer = styled.div`
  height: 100%;
  display: flex;

  transition: translate;
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
`;
const StyledCarouselButton = styled.div`
  position: absolute;

  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: #2c5d5b9c;

  &:first-child {
    left: 0;
  }
  &:last-child {
    right: 0;
  }
  cursor: pointer;
  svg {
    width: 50px;
    height: 50px;
    path {
      fill: white;
    }
  }
  &:hover {
    background-color: #ffffff9c;
    path {
      fill: #2c5d5b;
    }
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;

export {
  StyledCarousel,
  StyledCarouselWindow,
  StyledCarouselItemsContainer,
  StyledCarouselButton,
};
