import React from "react";
import { StyledMaglumatlar } from "./styles";
import MiaCodex from "../../components/MiaCodex/MiaCodex";
import PageTitle from "../../components/PageTitle/PageTitle";
import LegalInformationCenter from "../../components/LegalInformationCenter/LegalInformationCenter";

const Maglumatlar = () => {
  return (
    <StyledMaglumatlar>
      <PageTitle title="Maglumatlar" />
      <LegalInformationCenter />
      <MiaCodex />
    </StyledMaglumatlar>
  );
};

export default Maglumatlar;
