import styled from "styled-components";
import { media } from "../../ui/media";

const StyledMainTemplate = styled.div`
  position: relative;
  max-width: 1920px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  ${media.TABLET} {
    max-width: 768px;
  }
  ${media.PHONE} {
    max-width: 415px;
  }
`;
const StyledMainTemplateContainer = styled.div``;
const StyledHeaderNav = styled.nav`
  display: none;

  ${media.PHONE} {
    display: ${({ open }) => (open ? "block" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-height: 100vh;
    height: 100%;
    padding: 20px;
    background: #2c5d5b;
    border-left: 1px solid #ffffff81;
    z-index: 5;
    a {
      text-decoration: none;
      color: #ffffff;
    }
    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 20px;
      font-family: DM Sans;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      li {
        cursor: pointer;
        margin: 0 auto;
        padding: 5px;
      }
      li:first-child {
        margin: 0 20px 0 auto;
      }

      li:last-child {
        width: 140px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: #e8fffe;
        font-size: 14px;
        line-height: 18px;

        a {
          color: #2c5d5b;
        }
        svg {
          margin-right: 11px;
        }
      }
    }
  }
`;

const StyledBurgermenuCloseButton = styled.li``;
export {
  StyledMainTemplate,
  StyledMainTemplateContainer,
  StyledHeaderNav,
  StyledBurgermenuCloseButton,
};
