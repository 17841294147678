import React from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledYangynToleg,
  StyledPaymentTypesTitle,
  StyledPaymentTypes,
  StyledPaymentTypesItem,
  StyledPaymentTypesItemActive,
  StyledPaymentTypesItemContainer,
  StyledImage,
  StyledTitle,
  StyledPaymentTypesItemButton,
  StyledPaymentInfo,
  StyledPaymentInstructions,
  StyledInstructionsCircleContainer,
  StyledInstructionsCircle,
  StyledInstructionsDescriptionContainer,
  StyledInstructionsDescription,
  StyledInstructionsLine,
  StyledPaymentDescription,
  StyledPaymentInfoForm,
  StyledPaymentInfoInputs,
  StyledPaymentInfoInputContainer,
  StyledInput,
  StyledPaymentInfoFormDescription,
  StyledPaymentInfoFormSubmitButton,
} from "./styles";

import fire from "../../assets/fire.webp";
import policecar from "../../assets/policecar.webp";
import policemen from "../../assets/policemen.webp";
import IconSelector from "../../ui/IconSelector";

const YangynToleg = () => {
  const navigate = useNavigate();

  const handlePaymentClick = () => {
    navigate("/tölegler/ýangyn-howpsuzlyk-tölegler");
  };

  // navigate(`/search/${title}/${item}`);
  return (
    <StyledYangynToleg>
      <StyledPaymentTypes>
        <StyledPaymentTypesTitle>Töleg görnüşleri</StyledPaymentTypesTitle>
        <StyledPaymentTypesItemActive>
          <StyledPaymentTypesItemContainer className="fire">
            <StyledImage>
              <img className="fire_image" src={fire} alt="fire" />
            </StyledImage>
            <StyledTitle>Ýangyn howpsuzlyk tölegler</StyledTitle>
          </StyledPaymentTypesItemContainer>
          <StyledPaymentTypesItemButton>
            <p>Giňişleýin</p>
            <IconSelector id="next" />
          </StyledPaymentTypesItemButton>
        </StyledPaymentTypesItemActive>
        <StyledPaymentTypesItem onClick={handlePaymentClick}>
          <StyledPaymentTypesItemContainer className="policecar">
            <StyledImage>
              <img
                className="policecar_image"
                src={policecar}
                alt="policecar"
              />
            </StyledImage>
            <StyledTitle>P.Ý.G.G Tölegler</StyledTitle>
          </StyledPaymentTypesItemContainer>
          <StyledPaymentTypesItemButton>
            <p>Giňişleýin</p>
            <IconSelector id="next" />
          </StyledPaymentTypesItemButton>
        </StyledPaymentTypesItem>
        <StyledPaymentTypesItem>
          <StyledPaymentTypesItemContainer className="policemen">
            <StyledImage>
              <img
                className="policemen_image"
                src={policemen}
                alt="policemen"
              />
            </StyledImage>
            <StyledTitle>Ýerli wekil jerimeler</StyledTitle>
          </StyledPaymentTypesItemContainer>
          <StyledPaymentTypesItemButton>
            <p>Giňişleýin</p>
            <IconSelector id="next" />
          </StyledPaymentTypesItemButton>
        </StyledPaymentTypesItem>
      </StyledPaymentTypes>
      <StyledPaymentInfo>
        <StyledPaymentInstructions>
          <StyledInstructionsCircleContainer>
            <StyledInstructionsCircle>1</StyledInstructionsCircle>
            <StyledInstructionsLine>
              <IconSelector id="payment-line" />{" "}
            </StyledInstructionsLine>
            <StyledInstructionsCircle>2</StyledInstructionsCircle>
            <StyledInstructionsLine>
              <IconSelector id="payment-line" />{" "}
            </StyledInstructionsLine>
            <StyledInstructionsCircle>3</StyledInstructionsCircle>
          </StyledInstructionsCircleContainer>
          <StyledInstructionsDescriptionContainer>
            <StyledInstructionsDescription>
              Gerekli maglumatlary doly we dogry dolduryň
            </StyledInstructionsDescription>

            <StyledInstructionsDescription>
              Bank kartyňyz barada maglumatlary giriziň
            </StyledInstructionsDescription>

            <StyledInstructionsDescription>
              Töleg üstünlikli amala aşyryldy
            </StyledInstructionsDescription>
          </StyledInstructionsDescriptionContainer>
        </StyledPaymentInstructions>
        <StyledPaymentDescription>
          Telekom internet hasabyňyzy "Altyn asyr" plastik karty hem-de
          Türkmenistanyň Döwlet daşary ykdysady iş bankynyň "Milli Visa" karty
          arkaly online görnüşde dolduryp bilersiňiz
        </StyledPaymentDescription>
        <StyledPaymentInfoForm>
          <StyledPaymentInfoInputs>
            <StyledPaymentInfoInputContainer>
              <StyledInput>
                <label htmlFor="velayat">Welaýat</label>
                <div name="etrap" id="etrap">
                  <p>Aşgabat</p> <IconSelector id="black-dropdown" />
                </div>
              </StyledInput>
              <StyledInput>
                <label htmlFor="etrap">Şäher / etrap</label>
                <div name="etrap" id="etrap">
                  <p>Aşgabat</p> <IconSelector id="black-dropdown" />
                </div>
              </StyledInput>
            </StyledPaymentInfoInputContainer>
            <StyledInput>
              <label htmlFor="card">Kart</label>
              <input type="text" id="card" />
            </StyledInput>
            <StyledPaymentInfoInputContainer>
              <StyledInput>
                <label htmlFor="phone">Telefon belgiňiz:</label>
                <input type="text" id="phone" placeholder="+993" />
              </StyledInput>
              <StyledInput>
                <label htmlFor="cost">Manat</label>
                <input type="number" id="cost" />
              </StyledInput>
            </StyledPaymentInfoInputContainer>
          </StyledPaymentInfoInputs>
          <StyledPaymentInfoFormDescription>
            * Hyzmat üçin töleg 1 manatdan 500 manat aralygynda
          </StyledPaymentInfoFormDescription>
          <StyledPaymentInfoFormSubmitButton>
            <IconSelector id="credit-card" />
            <p>Tölemek</p>
          </StyledPaymentInfoFormSubmitButton>
        </StyledPaymentInfoForm>
      </StyledPaymentInfo>
    </StyledYangynToleg>
  );
};

export default YangynToleg;
