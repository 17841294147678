import styled from "styled-components";
import { media } from "../../ui/media";

const StyledYangynToleg = styled.div`
  padding: 28px 150px 48px;
  display: flex;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;

const StyledPaymentTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;

const StyledPaymentTypesTitle = styled.h2`
  font-family: DM Sans;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  margin-bottom: 8px;
`;

const StyledPaymentTypesItem = styled.div`
  position: relative;
  width: 379px;
  height: 204px;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.65s;
  place-self: center;

  img {
    transition: all 1.2s;
  }
  &:hover {
    margin: -10px;
    width: 399px;
    height: 224px;
    h2 {
    }
    .fire_image {
      width: 100px;
      height: 120px;
      margin-right: 20px;
    }

    .policecar_image {
      width: 149px;
      height: 122px;
      margin-right: 20px;
    }

    .policemen_image {
      width: 132px;
      height: 127px;
      margin-right: 20px;
    }
  }
`;

const StyledPaymentTypesItemActive = styled.div`
  position: relative;
  width: 379px;
  height: 204px;
  border: 1px solid #d9d9d9;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  background-color: #2c5d5b;
  color: #ffffff;
  button {
    p {
      color: #ffffff;
    }
  }
  path {
    fill: #ffffff;
  }
`;
const StyledImage = styled.div`
  .fire_image {
    width: 80px;
    height: 100px;
    margin-right: 10px;
  }

  .policecar_image {
    width: 129px;
    height: 102px;
    margin-right: 10px;
  }

  .policemen_image {
    width: 112px;
    height: 107px;
    margin-right: 10px;
  }
`;
const StyledPaymentTypesItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.h2`
  font-family: DM Sans;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;

  width: 215px;
`;

const StyledPaymentTypesItemButton = styled.button`
  position: absolute;
  bottom: 17px;
  right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  p {
    margin-right: 4px;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #00329b;
  }
`;

const StyledPaymentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
  margin-left: 57px;
  ${media.TABLET} {
    margin-left: 0px;
    align-items: normal;
    justify-content: normal;
  }
  ${media.PHONE} {
  }
`;
const StyledPaymentInstructions = styled.div`
  max-width: 785px;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    display: none;
  }
`;

const StyledInstructionsCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  margin-bottom: 13px;
  ${media.TABLET} {
  }
`;

const StyledInstructionsCircle = styled.div`
  width: 57px;
  height: 57px;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  color: #000000;
  place-self: center;
`;

const StyledInstructionsDescriptionContainer = styled.div`
  display: flex;

  gap: 85px;
  ${media.TABLET} {
    justify-content: center;
    gap: 60px;
  }
`;
const StyledInstructionsDescription = styled.p`
  width: 200px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: #000000;
  ${media.TABLET} {
  }
`;
const StyledInstructionsLine = styled.div`
  ${media.TABLET} {
    svg {
      width: 150px;
    }
  }
`;

const StyledPaymentDescription = styled.h2`
  max-width: 785px;
  width: 100%;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  margin-bottom: 34px;
`;
const StyledPaymentInfoForm = styled.form`
  max-width: 785px;
  width: 100%;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const StyledPaymentInfoInputs = styled.div``;
const StyledPaymentInfoInputContainer = styled.div`
  display: grid;
  grid-template-columns: 355px 355px;
  justify-content: space-between;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const StyledInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 22px;
  div {
    width: 100%;
    height: 61px;
    padding: 19px;
    border-radius: 12px;
    border: 1px solid #dadada;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      font-family: DM Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
    }
  }
  input {
    width: 100%;
    height: 61px;
    padding: 19px;
    border-radius: 12px;
    border: 1px solid #dadada;
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    &[type="number"] {
      appearance: textfield; /* Firefox */
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none; /* Chrome */
      margin: 0; /* Firefox */
    }
  }
  label {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #2c5d5b;
  }
`;
const StyledPaymentInfoFormDescription = styled.p`
  margin-bottom: 64px;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #0500ff;
`;
const StyledPaymentInfoFormSubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 354px;
  height: 60px;
  margin: 0 auto;
  padding: 18px 126px;
  gap: 10px;
  border-radius: 12px;
  background: #2c5d5b;
  p {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: #ffffff;
  }
`;

export {
  StyledYangynToleg,
  StyledPaymentTypesTitle,
  StyledPaymentTypes,
  StyledPaymentTypesItem,
  StyledPaymentTypesItemActive,
  StyledPaymentTypesItemContainer,
  StyledImage,
  StyledTitle,
  StyledPaymentTypesItemButton,
  StyledPaymentInfo,
  StyledPaymentInstructions,
  StyledInstructionsCircleContainer,
  StyledInstructionsCircle,
  StyledInstructionsDescriptionContainer,
  StyledInstructionsDescription,
  StyledInstructionsLine,
  StyledPaymentDescription,
  StyledPaymentInfoForm,
  StyledPaymentInfoInputs,
  StyledPaymentInfoInputContainer,
  StyledInput,
  StyledPaymentInfoFormDescription,
  StyledPaymentInfoFormSubmitButton,
};
