import styled from "styled-components";
import { media } from "../../ui/media";

const StyledSalgylarMap = styled.div`
  margin-bottom: 52px;
  .leaflet-container {
    height: 562px;

    .leaflet-control-attribution {
      display: none;
    }
    .cluster-icon {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      background-color: #fff;
      transform: translate(-50%, -50%);
    }
  }
  ${media.TABLET} {
    margin-bottom: 32px;
    .leaflet-container {
      height: 362px;

      .leaflet-control-attribution {
        display: none;
      }
      .cluster-icon {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }
  ${media.PHONE} {
  }
`;

export { StyledSalgylarMap };
