import styled from "styled-components";
import { media } from "../../ui/media";

const StyledJoinUs = styled.div``;
const StyledJoinUsContainer = styled.div`
  width: 100%;
  height: 158px;
  padding: 0 200px 0 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  background: #f8f8f8;
  @media screen and (max-width: 1630px) {
    padding: 0 100px 0 100px;
  }
  ${media.TABLET} {
    padding: 20px;
    height: max-content;
    flex-direction: column;
    margin-bottom: 28px;
  }
  ${media.PHONE} {
  }
`;
const StyledJoinUsTitle = styled.h2`
  width: 680px;
  font-family: DM Sans;
  font-size: 33px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  ${media.TABLET} {
    width: 100%;
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  ${media.PHONE} {
    width: 300px;
  }
`;
const StyledJoinUsMailInput = styled.div`
  display: flex;
  input {
    width: 384px;
    height: 56px;
    padding: 0px 1px 0px 20px;
    border-radius: 10px 0 0 10px;
    border: 1px solid #e2e1e1;
  }
  button {
    width: 140px;
    height: 56px;
    border-radius: 0px 10px 10px 0px;
    background: #2c5d5b;
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 26.04px;
    text-align: center;
    color: #ffffff;
  }
  ${media.TABLET} {
    input {
      width: 400px;
      height: 45px;
    }
    button {
      width: 135px;
      height: 45px;
    }
  }
  ${media.PHONE} {
    input {
      width: 250px;
    }
    button {
      width: 65px;
    }
  }
`;
const StyledJoinUsBottomLine = styled.div`
  width: 100%;
  height: 18px;
  background: #4da29e;
  opacity: 50%;
`;

export {
  StyledJoinUs,
  StyledJoinUsContainer,
  StyledJoinUsTitle,
  StyledJoinUsMailInput,
  StyledJoinUsBottomLine,
};
