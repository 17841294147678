import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  StyledHeader,
  StyledHeaderInfo,
  StyledInfo,
  StyledClock,
  StyledCalendar,
  StyledPhone,
  StyledLanguageContainer,
  StyledLanguage,
  StyledLanguageDropdown,
  StyledLanguageDropdownItem,
  StyledFlag,
  StyledMenuBar,
  StyledLogoContainer,
  StyledHeaderLogo,
  StyledSearchInput,
  StyledYearLogo,
  StyledHeaderNav,
  StyledHeaderNews,
  StyledLastNews,
  StyledLastNewsTitle,
  StyledPreviousNews,
  StyledNextNews,
} from "./styles";
import IconSelector from "../../ui/IconSelector";
import tiimlogo from "../../assets/tiimlogo.webp";
import magtymguly from "../../assets/magtymguly.webp";
import shevron from "../../assets/shevron.webp";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { openMenu } from "../../store/slices/burgerMenuSlice";
import {
  getLanguage,
  getLanguageDropdown,
  getLanguageOptions,
} from "../../store/selectors/languageSelectors";
import {
  changeLanguage,
  closeLanguageDropDown,
  openLanguageDropDown,
} from "../../store/slices/languageSlice";

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languageDropDown = useAppSelector(getLanguageDropdown);
  const languageOptions = useAppSelector(getLanguageOptions);
  const language = useAppSelector(getLanguage);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const daysInTurkish = {
    Monday: "Duşenbe",
    Tuesday: "Sişenbe",
    Wednesday: "Çarşenbe",
    Thursday: "Penşenbe",
    Friday: "Anna",
    Saturday: "Şenbe",
    Sunday: "Ýekşenbe",
  };

  const handleBurgerMenu = () => {
    dispatch(openMenu());
  };

  const dayOfWeek = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
  });
  const turkishDayOfWeek = daysInTurkish[dayOfWeek] || dayOfWeek;

  const handleLanguageDropdown = () => {
    if (languageDropDown) {
      dispatch(closeLanguageDropDown());
    } else {
      dispatch(openLanguageDropDown());
    }
  };
  const handleLanguage = (e) => {
    dispatch(changeLanguage(e.currentTarget.id));
    dispatch(closeLanguageDropDown());
  };

  const handleHeaderLogo = () => {
    navigate(`/`);
  };
  const handleHeaderNav = (event) => {
    const id = event.target.id;
    navigate(id);
  };

  return (
    <StyledHeader>
      <StyledHeaderInfo>
        <StyledInfo>
          <StyledClock>
            <IconSelector id="clock" />
            <p>
              {currentTime.getHours()}:
              {String(currentTime.getMinutes()).padStart(2, "0")}
            </p>
          </StyledClock>
          <StyledCalendar>
            <IconSelector id="calendar" />
            <p>
              {currentTime
                .toLocaleDateString("tr-TR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\./g, "-")}
              , {turkishDayOfWeek}
            </p>
          </StyledCalendar>
          <StyledPhone>
            <IconSelector id="phone" />
            <p>+99364929340</p>
          </StyledPhone>
        </StyledInfo>
        <StyledLanguageContainer>
          <StyledLanguage onClick={handleLanguageDropdown}>
            <StyledFlag
              src={
                languageOptions.find((option) => option.id === language)?.flag
              }
              alt="flag"
            />
            <p>Dil saýlaň:</p>
            <IconSelector id="dropdown" />
          </StyledLanguage>
          <StyledLanguageDropdown open={languageDropDown}>
            {languageOptions.map((option) => (
              <StyledLanguageDropdownItem
                key={option.id}
                id={option.id}
                href="#"
                onClick={handleLanguage}
              >
                <p>{option.name}</p>
                <StyledFlag src={option.flag} alt={option.name} />
              </StyledLanguageDropdownItem>
            ))}
          </StyledLanguageDropdown>
        </StyledLanguageContainer>
      </StyledHeaderInfo>
      <StyledLogoContainer>
        <StyledHeaderLogo onClick={handleHeaderLogo}>
          <img src={tiimlogo} alt="tiimlogo" />
        </StyledHeaderLogo>
        <StyledSearchInput>
          <input type="text" />
          <IconSelector id="search" />
        </StyledSearchInput>
        <StyledYearLogo>
          {language === "en" ? (
            <p>Year 2024 - «Magtymguly Fraghi's fount of Mind»</p>
          ) : language === "ru" ? (
            <p>2024 год - «Кладезь разума Махтумкули Фраги»</p>
          ) : (
            <p>2024-nji ýyl - «Pähim-paýhas ummany Magtymguly Pyragy» ýyly</p>
          )}

          <img src={magtymguly} alt="Pähim-paýhas ummany Magtymguly Pyragy" />
        </StyledYearLogo>
        <StyledMenuBar onClick={handleBurgerMenu}>
          <IconSelector id="burgermenu" />
        </StyledMenuBar>
      </StyledLogoContainer>
      <StyledHeaderNav>
        <ul>
          <li>
            <div onClick={handleHeaderNav} id="/">
              Baş sahypa
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/habarlar">
              Habarlar
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/maglumatlar">
              Maglumatlar
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/multimediýa">
              Multimediýa
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/salgylar">
              Salgylar
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/tölegler">
              Onlaýn tölegler
            </div>
          </li>
          <li>
            <div onClick={handleHeaderNav} id="/şahsy-otag">
              <IconSelector id="user" />
              <p>Şahsy otag</p>
            </div>
          </li>
        </ul>
      </StyledHeaderNav>
      <StyledHeaderNews>
        <StyledLastNews>
          <p>Soňky habarlar:</p>
          <p>31-05-2023</p>
        </StyledLastNews>
        <StyledLastNewsTitle>
          <img src={shevron} alt="shevron" />
          <p>Merkezi aziýa nobatdaky hyzmatdaşlygy</p>
        </StyledLastNewsTitle>
        <StyledPreviousNews>
          <IconSelector id="headerPrevius" />
        </StyledPreviousNews>
        <StyledNextNews>
          <IconSelector id="headerNext" />
        </StyledNextNews>
      </StyledHeaderNews>
    </StyledHeader>
  );
};

export default Header;
