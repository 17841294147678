import React, { useEffect } from "react";

import {
  StyledJournal,
  StyledTitle,
  StyledJournalContainer,
  StyledPrevious,
  StyledNext,
  StyledJournalItemsContainer,
  StyledJournalItem,
} from "./styles";
import IconSelector from "../../ui/IconSelector";
import { getRecentJournals } from "../../store/selectors/recentJournalsSelectors";
import { fetchRecentJournals } from "../../store/slices/recentJournalSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";

const Journal = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRecentJournals());
  }, [dispatch]);
  const recentJournals = useAppSelector(getRecentJournals);

  const handleJournalClick = (e) => {
    const pdfUrl = `${process.env.REACT_APP_FILE_URL}/${e.currentTarget.id}`;
    const pdfWindow = window.open("");
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfUrl}'></iframe>`
    );
  };

  return (
    <StyledJournal>
      <StyledTitle>{"«Asudalygyň goragynda» žurnaly"}</StyledTitle>
      <StyledJournalContainer>
        <StyledPrevious>
          <IconSelector id="headerPrevius" />
        </StyledPrevious>
        <StyledJournalItemsContainer>
          {recentJournals.map((item, index) => (
            <StyledJournalItem
              onClick={handleJournalClick}
              key={index}
              id={item.Journal}
            >
              <img
                src={`${process.env.REACT_APP_FILE_URL}/${item.Image}`}
                alt="journalimage"
              />
            </StyledJournalItem>
          ))}
        </StyledJournalItemsContainer>
        <StyledNext>
          <IconSelector id="headerNext" />
        </StyledNext>
      </StyledJournalContainer>
    </StyledJournal>
  );
};

export default Journal;
