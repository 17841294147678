import styled from "styled-components";
import { media } from "../../ui/media";
import { Link } from "react-router-dom";

const StyledMinistryNews = styled.div`
  padding: 18px 100px 55px;
  background-color: #f8f8f8;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;
const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 36px;
  ${media.TABLET} {
    margin-top: 0px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  ${media.PHONE} {
  }
`;
const StyledTitle = styled.h2`
  font-family: DM Sans;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #181818;
  text-align: center;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    font-size: 20px;
    line-height: 20px;
  }
`;
const StyledTitleLine = styled.div`
  width: 673px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;
const StyledTitleButton = styled.button`
  width: 163px;
  height: 41px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  background: none;
  opacity: 0px;
  font-family: DM Sans;
  font-weight: 700;

  text-align: center;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    width: max-content;
    height: max-content;
    padding: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;

const StyledNewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  grid-gap: 20px;
  @media only screen and (max-width: 1659px) {
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 20px;
    margin: -20px;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  @media only screen and (max-width: 1487px) {
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  }
  ${media.TABLET} {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 20px;
    margin: -20px;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  ${media.PHONE} {
  }
`;
const StyledNewsItem = styled(Link)`
  width: 321px;
  height: 425px;

  background-color: #f4f4f4;
  border-radius: 26px;
  box-shadow: 0px 0px 13px 0px #00000069;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  place-self: center;
  text-decoration: none;
  color: #000000;
  &:hover {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  ${media.TABLET} {
    flex: 0 0 auto;
    overflow-x: hidden; /* Scrol çubuğunu gizlemek için hidden */

    &:hover {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  ${media.PHONE} {
  }
`;
const StyledNewsImage = styled.div`
  img {
    border-radius: 26px 26px 0 0;
    width: 100%;
    height: 272px;
  }
`;
const StyledNewsViewsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 25px;
  font-family: DM Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #656565;
`;
const StyledNewsViews = styled.div`
  width: 69px;
  height: 31px;
  border-radius: 8px;
  background-color: #d9d9d9;
  opacity: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  svg {
    margin-right: 5px;
  }
  p {
    font-weight: 500;
    color: #000000;
  }
`;
const StyledNewsTitle = styled.h2`
  padding: 0 25px 0 25px;
  font-family: DM Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
`;

export {
  StyledMinistryNews,
  StyledTitleContainer,
  StyledTitle,
  StyledTitleLine,
  StyledTitleButton,
  StyledNewsContainer,
  StyledNewsItem,
  StyledNewsImage,
  StyledNewsViewsContainer,
  StyledNewsViews,
  StyledNewsTitle,
};
