import styled from "styled-components";
import { media } from "../../ui/media";

const StyledPageTitle = styled.div`
  width: 100%;
  padding: 12px 150px;
  border-bottom: 1px solid #ebebeb;
  background: #f5f5f5;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  ${media.TABLET} {
    padding: 12px 20px;
  }
  ${media.PHONE} {
    font-size: 14px;
  }
`;

export { StyledPageTitle };
