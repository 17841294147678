import React, { useState } from "react";
import {
  StyledLegalInformationCenter,
  StyledTitleContainer,
  StyledTitle,
  StyledDescription,
  StyledList,
  StyledListItem,
  StyledButton,
} from "./styles";
import IconSelector from "../../ui/IconSelector";

const LegalInformationCenter = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const items = [
    "Türkmenistanyň Konstitusiýasy",
    "Türkmenistanyň Konstitusiýasy",
    "Türkmenistanyň Konstitusiýasy",
    "Türkmenistanyň Konstitusiýasy",
    "Türkmenistanyň Konstitusiýasy",
    "Türkmenistanyň Konstitusiýasy",
  ];

  return (
    <StyledLegalInformationCenter>
      <StyledTitleContainer>
        <StyledTitle>Hukuk maglumatlar merkezi</StyledTitle>
        <StyledDescription>
          2024-nji ýylyň 01-nji martyna çenli girizilen üýtgetmeler we
          goşmaçalar bilen
        </StyledDescription>
      </StyledTitleContainer>
      <StyledList>
        {items.map((item, index) => (
          <StyledListItem key={index} onClick={() => setSelectedItem(index)}>
            <p>{item}</p>
            {selectedItem === index ? (
              <StyledButton>
                <IconSelector id="download" />
                <p>Ýüklap al</p>
              </StyledButton>
            ) : (
              <IconSelector id="dropdown-polygon" />
            )}
          </StyledListItem>
        ))}
      </StyledList>
    </StyledLegalInformationCenter>
  );
};

export default LegalInformationCenter;
