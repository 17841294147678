export function createPages(pages, pagesCount, currentPage) {
  pages.length = 0; // Yeni sayfalar eklemeden önce sayfalar dizisini temizle

  if (pagesCount > 6) {
    if (currentPage > 4) {
      for (
        let i = Math.max(currentPage - 3, 1);
        i <= Math.min(currentPage + 3, pagesCount);
        i++
      ) {
        pages.push(i);
      }
    } else {
      for (let i = 1; i <= 6 && i <= pagesCount; i++) {
        pages.push(i);
      }
    }
  } else {
    for (let i = 1; i <= pagesCount; i++) {
      pages.push(i);
    }
  }
}
