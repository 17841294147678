import styled from "styled-components";
import { media } from "../../ui/media";
import { Link } from "react-router-dom";

const StyledHabarlarItem = styled(Link)`
  width: 376px;
  height: 329px;
  text-decoration: none;
  border-radius: 12px;
  color: black;
  box-shadow: 0px 0px 12px 0px #00000040;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  ${media.TABLET} {
    width: 350px;
    height: 329px;
    &:hover {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  ${media.PHONE} {
  }
`;
const StyledHabarlarItemImage = styled.div`
  img {
    width: 376px;
    height: 219px;
    border-radius: 12px 12px 0 0;
  }
  ${media.TABLET} {
    img {
      width: 350px;
    }
  }
  ${media.PHONE} {
    img {
      width: 350px;
    }
  }
`;
const StyledHabarlarItemContainer = styled.div`
  padding: 10px 36px;
  ${media.TABLET} {
  }
  ${media.PHONE} {
  }
`;

const StyledHabarlarItemDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  svg {
    margin-right: 9px;
  }
`;
const StyledHabarlarItemTitle = styled.div`
  width: max-content;
  padding: 0 10px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  border-radius: 6px;
  background: #2c5d5b;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
`;
const StyledHabarlarItemDescription = styled.p`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export {
  StyledHabarlarItem,
  StyledHabarlarItemImage,
  StyledHabarlarItemContainer,
  StyledHabarlarItemDate,
  StyledHabarlarItemTitle,
  StyledHabarlarItemDescription,
};
