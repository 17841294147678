import styled from "styled-components";
import { media } from "../../ui/media";

const StyledJournal = styled.div`
  padding: 18px 100px 55px;
  ${media.TABLET} {
    padding: 20px;
  }
  ${media.PHONE} {
  }
`;
const StyledTitle = styled.h2`
  margin-bottom: 39px;
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  text-align: center;
  ${media.TABLET} {
  }
  ${media.PHONE} {
    margin-bottom: 29px;
    font-size: 20px;
    line-height: 20px;
  }
`;
const StyledJournalContainer = styled.div`
  display: flex;
`;
const StyledJournalItemsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px;
  &::-webkit-scrollbar {
    width: 0;
  }
  ${media.PHONE} {
  }
`;
const StyledJournalItem = styled.div`
  border-radius: 12px;
  box-shadow: 0px 0px 13px 0px #00000069;
  cursor: pointer;
  place-self: center;
  img {
    width: 208px;
    height: 277px;
    border-radius: 12px;
  }
  ${media.TABLET} {
    margin: 15px;
    box-shadow: 0px 0px 13px 0px #00000069;
  }
  ${media.PHONE} {
  }
`;
const StyledPrevious = styled.div`
  display: none;
  margin-top: 91px;
  width: 41px;
  height: 41px;

  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #000000;
  cursor: pointer;
  svg {
    path {
      fill: #000000;
    }
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;
const StyledNext = styled.div`
  display: none;
  margin-top: 91px;
  width: 41px;
  height: 41px;

  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #000000;
  cursor: pointer;
  svg {
    path {
      fill: #000000;
    }
  }
  ${media.TABLET} {
    display: none;
  }
  ${media.PHONE} {
  }
`;

export {
  StyledJournal,
  StyledTitle,
  StyledJournalContainer,
  StyledJournalItemsContainer,
  StyledJournalItem,
  StyledPrevious,
  StyledNext,
};
