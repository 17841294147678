import { $host } from "./index";

// export const getMinistryNewsById = async (id) => {
//   const { data } = await $host.get(`/get_main_slider_by_id/${id}`);
//   return data;
// };

// export const getAllMinistryNews = async () => {
//   const { data } = await $host.get("/get_type_of_news_by_id/1");
//   return data;
// };
// export const getRecentMinistryNews = async () => {
//   const { data } = await $host.get("get_recent_news_by_type_id/1");
//   return data;
// };
export const getRecentJournals = async () => {
  const { data } = await $host.get("get_recent_journals");
  return data;
};
