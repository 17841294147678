import React from "react";
import {
  StyledHeaderNav,
  StyledMainTemplate,
  StyledMainTemplateContainer,
  StyledBurgermenuCloseButton,
} from "./styles";
import { Outlet } from "react-router-dom";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import JoinUs from "../../components/JoinUs/JoinUs";
import IconSelector from "../../ui/IconSelector";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { getBurgerMenu } from "../../store/selectors/burgerMenuSelectors";
import { closeMenu } from "../../store/slices/burgerMenuSlice";

const MainTemplate = () => {
  const burgerMenu = useAppSelector(getBurgerMenu);
  const dispatch = useAppDispatch();

  const handleCloseBurgerMenu = () => {
    dispatch(closeMenu());
  };

  return (
    <StyledMainTemplate>
      <StyledMainTemplateContainer>
        <Header />
        <StyledHeaderNav open={burgerMenu}>
          <ul>
            <StyledBurgermenuCloseButton onClick={handleCloseBurgerMenu}>
              <IconSelector id="burgermenu-close" />
            </StyledBurgermenuCloseButton>
            <li>
              <a href="/">Baş sahypa</a>
            </li>
            <li>
              <a href="/habarlar">Habarlar</a>
            </li>
            <li>
              <a href="/maglumatlar">Maglumatlar</a>
            </li>
            <li>
              <a href="/multimediýa">Multimediýa</a>
            </li>
            <li>
              <a href="/salgylar">Salgylar</a>
            </li>
            <li>
              <a href="/tölegler">Onlaýn tölegler</a>
            </li>
            <li>
              <IconSelector id="user" />
              <a href="/şahsy-otag">Şahsy otag</a>
            </li>
          </ul>
        </StyledHeaderNav>
        <Outlet />
        <JoinUs />
        <Footer />
      </StyledMainTemplateContainer>
    </StyledMainTemplate>
  );
};

export default MainTemplate;
