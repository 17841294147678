import React from "react";
import { StyledSalgylar } from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import SalgylarInformation from "../../components/SalgylarInformation/SalgylarInformation";
import MiaDepartments from "../../components/MiaDepartments/MiaDepartments";
import SalgylarMap from "../../components/SalgylarMap/SalgylarMap";

const Salgylar = () => {
  return (
    <StyledSalgylar>
      <PageTitle title="Salgylar" />
      <SalgylarInformation />
      <MiaDepartments />
      <SalgylarMap />
    </StyledSalgylar>
  );
};

export default Salgylar;
