import React from "react";
import YangynToleg from "../../components/YangynToleg/YangynToleg";
import PageTitle from "../../components/PageTitle/PageTitle";

const YangynPay = () => {
  return (
    <div>
      <PageTitle title="Tölegler / Ýangyn howpsuzlyk tölegler " />
      <YangynToleg />
    </div>
  );
};

export default YangynPay;
