import React from "react";
import { StyledMultimedia } from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import Photogallery from "../../components/Photogallery/Photogallery";
import VideoGallery from "../../components/VideoGallery/VideoGallery";

const Multimedia = () => {
  return (
    <StyledMultimedia>
      <PageTitle title="Multimediýa" />
      <Photogallery />
      <VideoGallery />
    </StyledMultimedia>
  );
};

export default Multimedia;
