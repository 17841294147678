import React from "react";
import {
  StyledHabarlar,
  StyledHabarlarHeader,
  StyledButtonContainer,
  StyledHabarlarButton,
  StyledSearchInput,
  StyledHabarlarContainer,
  StyledModalContainer,
} from "./styles";

import HabarlarItem from "../../components/HabarlarItem/HabarlarItem";
import IconSelector from "../../ui/IconSelector";
import {
  getNews,
  getNewsStatus,
  getNewsTotalPage,
} from "../../store/selectors/newsSelectors";

import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks/hooks";
import { useEffect } from "react";
import { fetchNews } from "../../store/slices/newsSlice";
import { formatDate } from "../../components/formatDate";
import PageControl from "../../components/PageControl/PageControl";

const Habarlar = () => {
  const [typeOfNewsID, setTypeOfNewsID] = useState("");
  const [searchedNews, setSearchedNews] = useState("");
  const limit = "20";
  const [currentPage, setCurrentPage] = useState("1");

  const dispatch = useAppDispatch();
  const news = useAppSelector(getNews);
  const status = useAppSelector(getNewsStatus);
  const totalPage = useAppSelector(getNewsTotalPage);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page whenever the route changes
  }, [currentPage, typeOfNewsID, searchedNews]);

  useEffect(() => {
    dispatch(
      fetchNews({ typeOfNewsID, searchedNews, limit, page: currentPage })
    );
  }, [dispatch, typeOfNewsID, searchedNews, limit, currentPage]);
  useEffect(() => {
    setCurrentPage("1");
  }, [typeOfNewsID, searchedNews]);

  const handleSearch = (e) => {
    setSearchedNews(e.target.value);
  };

  const handlePage = (item) => {
    setCurrentPage(String(item));
  };

  const handleNextPage = () => {
    if (Number(currentPage) === totalPage) {
      return;
    }
    setCurrentPage(String(Number(currentPage) + 1));
  };

  const handlePrevPage = () => {
    if (Number(currentPage) === 1) {
      return;
    }
    setCurrentPage(String(Number(currentPage) - 1));
  };

  return (
    <StyledHabarlar>
      <StyledModalContainer></StyledModalContainer>

      <StyledHabarlarHeader>
        <StyledButtonContainer>
          <StyledHabarlarButton
            onClick={() => {
              setTypeOfNewsID("");
            }}
            isActive={typeOfNewsID === "" ? true : false}
          >
            Habarlar
          </StyledHabarlarButton>
          <StyledHabarlarButton
            onClick={() => {
              setTypeOfNewsID("1");
            }}
            isActive={typeOfNewsID === "1" ? true : false}
          >
            Ministrligiň täzelikleri
          </StyledHabarlarButton>
          <StyledHabarlarButton
            onClick={() => {
              setTypeOfNewsID("2");
            }}
            isActive={typeOfNewsID === "2" ? true : false}
          >
            Beýleki habarlar
          </StyledHabarlarButton>
        </StyledButtonContainer>
        <StyledSearchInput>
          <input type="text" onChange={handleSearch} placeholder="Gözleg..." />
          <IconSelector id="search" />
        </StyledSearchInput>
      </StyledHabarlarHeader>
      <StyledHabarlarContainer>
        {news.map((item, index) => (
          <HabarlarItem
            key={index}
            img={`${process.env.REACT_APP_API_URL}/${item.Image}`}
            date={formatDate(item.CreatedAt)}
            title={
              item.TypeOfNewsID === 1
                ? "Ministrligiň täzelikleri"
                : "Beýleki habarlar"
            }
            description={item.TitleTm}
            itemId={item.ID}
          />
        ))}
      </StyledHabarlarContainer>
      <PageControl
        handlePrevPage={handlePrevPage}
        handlePage={handlePage}
        handleNextPage={handleNextPage}
        totalPage={Number(totalPage)}
        currentPage={Number(currentPage)}
      />
    </StyledHabarlar>
  );
};

export default Habarlar;
