import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";

import "leaflet/dist/leaflet.css";
import { StyledSalgylarMap } from "./styles";
import customMarkerIcon from "../../assets/location.webp";

function SalgylarMap() {
  // Create a custom icon for the marker
  const customIcon = new Icon({
    iconUrl: customMarkerIcon,
    iconSize: [54, 70],
  });

  return (
    <StyledSalgylarMap>
      <MapContainer center={[37.9427715, 58.3815672]} zoom={22}>
        {/* Add an attribution to the TileLayer */}
        <TileLayer
          attribution="&copy; Türkmenistanyň Içeri işler ministirligi"
          url="http://192.168.51.66:8080/styles/basic-preview/{z}/{x}/{y}.png"
        />

        {/* Add a Marker with a custom icon */}
        <Marker
          position={[37.94278197672057, 58.38168333312421]}
          icon={customIcon}
        >
          <Popup>
            <h2>Aşgabat şäher polisiýa müdirligi</h2>
          </Popup>
        </Marker>
      </MapContainer>
    </StyledSalgylarMap>
  );
}

export default SalgylarMap;
