import React, { useState } from "react";
import {
  StyledJoinUs,
  StyledJoinUsContainer,
  StyledJoinUsTitle,
  StyledJoinUsMailInput,
  StyledJoinUsBottomLine,
} from "./styles";

import { sendMail } from "../../services/mailApi";

const JoinUs = () => {
  const [mail, setMail] = useState("");
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleMail = () => {
    if (validateEmail(mail)) {
      sendMail(mail);
      alert("E-posta başarıyla gönderildi!");
      setMail("");
    } else {
      alert("Lütfen geçerli bir e-posta girin!");
    }
  };

  const handleInputChange = (e) => {
    setMail(e.target.value);
  };

  return (
    <StyledJoinUs>
      <StyledJoinUsContainer>
        <StyledJoinUsTitle>
          BIZE GOŞULYŇ WE BIZIŇ TÄZELIKLERIMIZDEN HABARLY BOLUŇ!
        </StyledJoinUsTitle>
        <StyledJoinUsMailInput>
          <input
            type="email"
            placeholder="Email"
            value={mail}
            onChange={handleInputChange}
          />
          <button onClick={handleMail}>Ugrat</button>
        </StyledJoinUsMailInput>
      </StyledJoinUsContainer>
      <StyledJoinUsBottomLine />
    </StyledJoinUs>
  );
};

export default JoinUs;
