import React from "react";
import { StyledHabarlarInfo } from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import HabarlarDescription from "../../components/HabarlarDescription/HabarlarDescription";
import { useAppSelector } from "../../store/hooks/hooks";
import { getNewsById } from "../../store/selectors/newsByIdSelectors";

const HabarlarInfo = () => {
  const newsTitle = useAppSelector(getNewsById);

  return (
    <StyledHabarlarInfo>
      <PageTitle title={`Habarlar / ${newsTitle.TitleTm}`} />
      <HabarlarDescription />
    </StyledHabarlarInfo>
  );
};

export default HabarlarInfo;
