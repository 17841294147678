import { $host } from "./index";

export const getNewsByIdentificator = async (id) => {
  const { data } = await $host.get(`get_news_by_id/${id}`);
  return data;
};

export const getAllMinistryNews = async () => {
  const { data } = await $host.get("/get_type_of_news_by_id/1");
  return data;
};

export const getMoreWatchedNews = async () => {
  const { data } = await $host.get("get_more_watched_news");
  return data;
};

export const getRecentMinistryNews = async () => {
  const { data } = await $host.get("get_recent_news_by_type_id/1");
  return data;
};
export const getRecentOtherNews = async () => {
  const { data } = await $host.get("get_recent_news_by_type_id/2");
  return data;
};

export const updateViews = async (id) => {
  const { data } = await $host.put(`update_views_of_news/${id}`);
  return data;
};
export const getSearchedNews = async (
  typeOfNewsID,
  searchedNews,
  limit,
  page
) => {
  const { data } = await $host.post(`get_searched_news`, {
    typeOfNewsID,
    searchedNews,
    limit,
    page,
  });
  return data;
};
