import React from "react";
import MiaCodeximage from "../../assets/magazineimage.webp";
import {
  StyledMiaCodex,
  StyledTitle,
  StyledMiaCodexContainer,
  StyledPrevious,
  StyledNext,
  StyledMiaCodexItemsContainer,
  StyledMiaCodexItem,
} from "./styles";
import IconSelector from "../../ui/IconSelector";

const MiaCodex = () => {
  return (
    <StyledMiaCodex>
      <StyledTitle>
        Türkmenistanyň Içeri işler ministrligi tarapyndan neşir edilen kodeksler
      </StyledTitle>
      <StyledMiaCodexContainer>
        <StyledPrevious>
          <IconSelector id="headerPrevius" />
        </StyledPrevious>
        <StyledMiaCodexItemsContainer>
          <StyledMiaCodexItem>
            <img src={MiaCodeximage} alt="MiaCodeximage" />
          </StyledMiaCodexItem>
          <StyledMiaCodexItem>
            <img src={MiaCodeximage} alt="MiaCodeximage" />
          </StyledMiaCodexItem>
          <StyledMiaCodexItem>
            <img src={MiaCodeximage} alt="MiaCodeximage" />
          </StyledMiaCodexItem>
          <StyledMiaCodexItem>
            <img src={MiaCodeximage} alt="MiaCodeximage" />
          </StyledMiaCodexItem>
          <StyledMiaCodexItem>
            <img src={MiaCodeximage} alt="MiaCodeximage" />
          </StyledMiaCodexItem>
        </StyledMiaCodexItemsContainer>
        <StyledNext>
          <IconSelector id="headerNext" />
        </StyledNext>
      </StyledMiaCodexContainer>
    </StyledMiaCodex>
  );
};

export default MiaCodex;
