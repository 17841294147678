import { $host } from "./index";

export const getMainSliderById = async (id) => {
  const { data } = await $host.get(`get_main_slider_by_id`, { id });
  return data;
};

export const getAllMainSliders = async (id) => {
  const { data } = await $host.get("get_all_main_slider");
  return data;
};
