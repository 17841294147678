import React from "react";
import {
  StyledHabarlarItem,
  StyledHabarlarItemImage,
  StyledHabarlarItemContainer,
  StyledHabarlarItemDate,
  StyledHabarlarItemTitle,
  StyledHabarlarItemDescription,
} from "./styles";
import IconSelector from "../../ui/IconSelector";

const HabarlarItem = ({ img, date, title, description, itemId }) => {
  return (
    <StyledHabarlarItem to={"/habarlar/" + itemId}>
      <StyledHabarlarItemImage>
        <img src={img} alt={title} />
      </StyledHabarlarItemImage>
      <StyledHabarlarItemContainer>
        <StyledHabarlarItemDate>
          <IconSelector id="calendar-habarlar" />
          <p>{date}</p>
          <StyledHabarlarItemTitle>{title}</StyledHabarlarItemTitle>
        </StyledHabarlarItemDate>
        <StyledHabarlarItemDescription>
          {description}
        </StyledHabarlarItemDescription>
      </StyledHabarlarItemContainer>
    </StyledHabarlarItem>
  );
};

export default HabarlarItem;
