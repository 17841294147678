import React from "react";
import { StyledVideoGallery } from "./styles";

import video from "../../assets/Army.mp4";
import video3 from "../../assets/Army3.mp4";

const VideoGallery = () => {
  return (
    <StyledVideoGallery>
      <div className="video-player">
        <video controls autoPlay muted loop>
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className="video-player">
        <video controls autoPlay muted loop>
          <source src={video3} type="video/mp4" />
        </video>
      </div>
    </StyledVideoGallery>
  );
};

export default VideoGallery;
