import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledHabarlarDescription = styled.div`
  padding: 44px 100px 76px;
`;

const StyledContainer = styled.div`
  display: flex;
  gap: 33px;
`;

const StyledMainContainer = styled.div`
  width: 100%;
`;

const StyledMainTitle = styled.div`
  font-family: DM Sans;
  font-size: 38px;
  font-weight: 700;
  line-height: 49.48px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 13px;
`;

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  svg {
    margin-right: 9px;
  }
`;

const StyledDateTitle = styled.div`
  width: max-content;
  padding: 0 10px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  border-radius: 6px;
  background: #2c5d5b;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
`;
const StyledViews = styled.div`
  width: 69px;
  height: 31px;
  border-radius: 8px;
  background-color: #d9d9d9;
  opacity: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  svg {
    margin-right: 5px;
  }
  p {
    font-weight: 500;
    color: #000000;
  }
`;

const StyledImage = styled.div`
  margin-bottom: 26px;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 347.5px;
  }
`;

const StyledDescription = styled.div`
  margin-bottom: 20px;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.01px;
  text-align: justify;
  color: #7e7e7e;
`;

const StyledSideBar = styled.div`
  width: 500px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
const StyledSideBarTitle = styled.div`
  font-family: Mulish;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  text-align: left;
  margin-bottom: 18px;
`;

const StyledSideBarItem = styled(Link)`
  min-height: 100px;
  display: flex;
  gap: 18px;
  background: #f0f0f0;
  text-decoration: none;
  color: #000000;
`;

const StyledSideBarItemImage = styled.div`
  margin: auto 0;
  img {
    width: 148px;
    min-height: 100px;
  }
`;

const StyledSideBarItemContainer = styled.div``;

const StyledSideBarItemTitle = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #25213b;
`;

const StyledSideBarItemDate = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #868686;
`;

export {
  StyledHabarlarDescription,
  StyledContainer,
  StyledMainContainer,
  StyledMainTitle,
  StyledDateContainer,
  StyledDateTitle,
  StyledViews,
  StyledImage,
  StyledDescription,
  StyledSideBar,
  StyledSideBarTitle,
  StyledSideBarItem,
  StyledSideBarItemImage,
  StyledSideBarItemContainer,
  StyledSideBarItemTitle,
  StyledSideBarItemDate,
};
